import React from "react";

import {
  ACCESS_TYPE_CONCIERGE,
  ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
  ACCESS_TYPE_KEYLESS_SMART_LOCK,
  ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM,
  ACCESS_TYPE_KEYNEST,
  ACCESS_TYPE_NEARBY_MERCHANT,
  ACCESS_TYPE_RAILING_LOCKBOX,
  ACCESS_TYPE_SALTO,
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX,
  ACCESS_TYPE_WELCOME_WIZARD
} from "../../../data/accessTypes";
import {
  EnjoyYourStayKeysReminder,
  EnjoyYourStay,
  CollectTheKeys,
  DigitalIntercom,
  FindTheProperty,
  DigitalLock,
  Alternative
} from "./steps";
import {
  Concierge,
  Keynest,
  Lockbox,
  NearbyMerchant,
  Salto,
  WelcomeWizard
} from "./steps/keys";

const stepsByAccessType = {
  [ACCESS_TYPE_WALL_MOUNTED_LOCKBOX]: [
    <CollectTheKeys key={0} instructions={<Lockbox />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_RAILING_LOCKBOX]: [
    <CollectTheKeys key={0} instructions={<Lockbox />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_KEYLESS_DIGITAL_LOCK]: [
    <CollectTheKeys key={0} instructions={<Lockbox />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStay key={2} />
  ],

  [ACCESS_TYPE_KEYNEST]: [
    <CollectTheKeys key={0} instructions={<Keynest />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_CONCIERGE]: [
    <CollectTheKeys key={0} instructions={<Concierge />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_NEARBY_MERCHANT]: [
    <CollectTheKeys
      key={0}
      instructions={<NearbyMerchant />}
    />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_WELCOME_WIZARD]: [
    <CollectTheKeys
      key={0}
      instructions={<WelcomeWizard />}
    />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_SALTO]: [
    <CollectTheKeys key={0} instructions={<Salto />} />,
    <FindTheProperty key={1} />,
    <EnjoyYourStayKeysReminder key={2} />
  ],
  [ACCESS_TYPE_KEYLESS_SMART_LOCK]: [
    <FindTheProperty key={0} />,
    <DigitalLock key={1} />,
    <Alternative key={2} />,
    <EnjoyYourStayKeysReminder key={3} />
  ],
  [ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM]: [
    <FindTheProperty key={0} />,
    <DigitalIntercom key={1} />,
    <DigitalLock key={2} />,
    <Alternative key={3} />,
    <EnjoyYourStayKeysReminder key={4} />
  ]
};

export default stepsByAccessType;
