import debounce from "lodash/debounce";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  useParams,
  useSearchParams
} from "react-router-dom";
import api from "services/api";

const ReviewContext = createContext({});

export const useReviewContext = () =>
  useContext(ReviewContext);

export const ReviewContextProvider = ({ children }) => {
  const { bookingToken } = useParams();
  const [searchParams] = useSearchParams();
  const searchQueryRating = searchParams.get("rating");

  const [cleanerCompliments, setCleanerCompliments] =
    useState([]);
  const [cleanIssues, setCleanIssues] = useState([]);
  const [cleanNotes, setCleanNotes] = useState("");
  const [cleanRating, setCleanRating] = useState(
    searchQueryRating ? searchQueryRating.toString() : 5
  );
  const [propertyRating, setPropertyRating] = useState(5);
  const [propertyNotes, setPropertyNotes] = useState("");
  const [positiveSuggestions, setPositiveSuggestions] =
    useState("");

  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [tipAmount, setTipAmount] = useState(5);
  const [tipCompleted, setTipCompleted] = useState(false);
  const [tipsEnabled, setTipsEnabled] = useState(false);
  const [cleanerStripeAccount, setCleanerStripeAccount] =
    useState("");
  const [cleanerName, setCleanerName] = useState("");
  const [stripeSecret, setStripeSecret] = useState(null);
  const [tipError, setTipError] = useState(null);
  const [formError, setFormError] = useState(false);

  const formValues = useMemo(
    () => ({
      clean_rating: cleanRating,
      property_rating: propertyRating
    }),
    [cleanRating, propertyRating]
  );

  const upsertBookingRating = useCallback(
    data =>
      api
        .patch("/v1/guests/rating", null, data)
        .then(res => {
          setCleanerStripeAccount(
            res.cleaner_stripe_account
          );

          return res;
        })
        .catch(() => {
          setHasErrors(true);

          return false;
        }),
    []
  );

  const debouncedUpsertBookingRating = debounce(
    async data => {
      setHasErrors(false);
      return upsertBookingRating(data);
    },
    500,
    { leading: true }
  );

  useEffect(() => {
    const fetchData = () => {
      api.setToken(bookingToken);
      setIsLoading(true);

      api
        .get("/v1/guests/rating")
        .then(res => {
          setIsCompleted(res.completed);
          setCleanerStripeAccount(
            res.cleaner_stripe_account
          );
          setCleanerName(res.cleaner_name);
          if (!res.completed) {
            setCleanerCompliments(
              res.cleaner_compliments || []
            );
            setCleanIssues(res.clean_issues || []);
            setCleanNotes(
              res.clean_notes === null
                ? ""
                : res.clean_notes
            );
            setCleanRating(res.clean_rating);
            setPropertyNotes(res.property_notes);
            setPropertyRating(res.property_rating);
            setPositiveSuggestions(
              res.positive_suggestions
            );
          }
        })
        .catch(error => {
          if (error.message !== "NOT_FOUND") {
            setHasErrors(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [bookingToken]);

  useEffect(() => {
    if (cleanRating === 5 && cleanerStripeAccount) {
      setTipsEnabled(true);
    }
  }, [cleanRating, cleanerStripeAccount]);

  useEffect(() => {
    // core-api creates payment intent with tip amount on each change tip change
    if (tipsEnabled && tipAmount) {
      setTipError(false);
      api
        .post("/v1/guests/rating/tips", null, {
          amount: tipAmount
        })
        .then(({ client_secret: secret }) => {
          setStripeSecret(secret);
        })
        .catch(() => {
          setTipError(true);
        });
    }
  }, [tipsEnabled, tipAmount]);

  const handleSetCleanRating = useCallback(
    value => {
      const newValue = +value;

      const values = {
        ...formValues,
        clean_rating: newValue
      };

      setCleanRating(newValue);

      if (newValue < 5) {
        values.cleaner_compliments = [];
        setCleanerCompliments(values.cleaner_compliments);
        values.positive_suggestions = "";
        setPositiveSuggestions(values.positive_suggestions);
      } else {
        values.clean_issues = [];
        setCleanIssues(values.clean_issues);
        values.clean_notes = "";
        setCleanNotes(values.clean_notes);
        values.property_notes = "";
        setPropertyNotes(values.property_notes);
      }

      debouncedUpsertBookingRating(values);
    },
    [formValues]
  );

  const handleSetPropertyRating = useCallback(
    value => {
      const newValue = +value;

      const values = {
        ...formValues,
        property_rating: newValue
      };

      if (newValue < 5) {
        values.positive_suggestions = "";
        setPositiveSuggestions("");
      } else {
        values.clean_notes = "";
        setPropertyNotes("");
      }

      setPropertyRating(+value);
      debouncedUpsertBookingRating(values);
    },
    [formValues]
  );

  const handleSetValues = useCallback(
    (value, valueName) => {
      const values = {
        ...formValues
      };

      switch (valueName) {
        case "cleanerCompliments":
          setCleanerCompliments(value);
          values.cleaner_compliments = value;
          break;
        case "cleanIssues":
          setCleanIssues(value);
          values.clean_issues = value;
          break;
        case "cleanNotes":
          setCleanNotes(value);
          values.clean_notes = value;
          break;
        case "propertyNotes":
          setPropertyNotes(value);
          values.property_notes = value;
          break;
        case "positiveSuggestions":
          setPositiveSuggestions(value);
          values.positive_suggestions = value;
          break;
        default:
          break;
      }
      debouncedUpsertBookingRating(values);
    },
    [formValues]
  );

  const handleFinish = useCallback(async () => {
    setIsSubmitting(true);
    setHasErrors(false);

    const data = {
      ...formValues,
      completed: true,
      cleaner_compliments: cleanerCompliments,
      clean_issues: cleanIssues,
      clean_notes: cleanNotes,
      property_notes: propertyNotes,
      positive_suggestions: positiveSuggestions
    };

    const result = await upsertBookingRating(data);

    setIsSubmitting(false);
    if (result) {
      setIsCompleted(true);
    }
  }, [
    formValues,
    cleanerCompliments,
    cleanIssues,
    cleanNotes,
    propertyNotes,
    positiveSuggestions
  ]);

  const handleSendTipAmount = useCallback(async value => {
    await upsertBookingRating({
      tip_amount: +value
    });
  }, []);

  useEffect(() => {
    if (searchQueryRating) {
      handleSetCleanRating(searchQueryRating);
    }
  }, [searchQueryRating]);

  const value = useMemo(
    () => ({
      cleanerCompliments,
      cleanIssues,
      cleanNotes,
      cleanRating,
      propertyRating,
      propertyNotes,
      positiveSuggestions,
      handleSetValues,
      handleSetCleanRating,
      handleSetPropertyRating,
      handleFinish,
      handleSendTipAmount,
      isCompleted,
      isLoading,
      setIsLoading,
      isSubmitting,
      hasErrors,
      tipAmount,
      setTipAmount,
      tipCompleted,
      setTipCompleted,
      cleanerName,
      cleanerStripeAccount,
      tipsEnabled,
      stripeSecret, // Returned from core-api when payment intent is created
      setStripeSecret,
      tipError,
      setTipError,
      formError,
      setFormError
    }),
    [
      cleanerCompliments,
      cleanIssues,
      cleanNotes,
      cleanRating,
      propertyRating,
      propertyNotes,
      positiveSuggestions,
      handleSetValues,
      handleSetCleanRating,
      handleSetPropertyRating,
      handleFinish,
      handleSendTipAmount,
      isCompleted,
      isLoading,
      setIsLoading,
      isSubmitting,
      hasErrors,
      tipAmount,
      setTipAmount,
      tipCompleted,
      setTipCompleted,
      cleanerStripeAccount,
      cleanerName,
      tipsEnabled,
      stripeSecret,
      setStripeSecret,
      tipError,
      setTipError,
      formError,
      setFormError
    ]
  );

  return (
    <ReviewContext.Provider value={value}>
      {children}
    </ReviewContext.Provider>
  );
};
