import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import {
  useAppContext,
  AppContextProvider
} from "AppContext";
import loader from "assets/loader.webp";
import { StayDetailsFormPage } from "scenes/StayDetailsFormPage";
import { StayDetailsPage } from "scenes/StayDetailsPage";

const InstructionsContainer = () => {
  const { booking, bookingLoading } = useAppContext();

  useEffect(() => {
    Sentry.captureMessage("User opened instructions");
  }, []);

  if (bookingLoading) {
    return (
      <img src={loader} alt="Loader" className="loader" />
    );
  }

  if (booking) {
    if (booking.terms_accepted) {
      return <StayDetailsPage />;
    }

    return <StayDetailsFormPage />;
  }

  return null;
};

export const Instructions = () => (
  <AppContextProvider>
    <InstructionsContainer />
  </AppContextProvider>
);
