import { InfoContainer } from "components/InfoContainer";

const PROPERTY_ACCESS_TYPE_KEYNEST = "keynest";

export const KeystoreInformation = ({
  contactNumber,
  guestKey,
  location
}) => {
  const openingTime =
    guestKey?.keystore_location?.opening_time;

  const isKeynest =
    guestKey &&
    location.access_type === PROPERTY_ACCESS_TYPE_KEYNEST;

  if (isKeynest && openingTime) {
    return (
      <InfoContainer testId="keynest-info">
        <p>
          The opening hours of the key store location is:{" "}
          {openingTime}
        </p>

        <p>
          {contactNumber && (
            <>
              If you are unable to check in or check out
              during the opening hours then please contact
              us at
              <a href={contactNumber}> {contactNumber} </a>
            </>
          )}
        </p>
      </InfoContainer>
    );
  }

  return null;
};
