import "./Input.scss";
import classNames from "classnames";
import { ErrorMessage, Field } from "formik";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";
import { LabelWithInfo } from "components/LabelWithInfo";

export const Input = ({
  name,
  type,
  component,
  placeholder,
  id,
  label,
  invalid,
  rows,
  children,
  labelWithInfo,
  infoText,
  step
}) => {
  return (
    <>
      {labelWithInfo ? (
        <LabelWithInfo
          htmlFor={name}
          label={label}
          infoText={infoText}
        />
      ) : (
        <label htmlFor={name} className="Input__label">
          {label}
        </label>
      )}

      <Field
        name={name}
        component={component}
        id={id || name}
        type={type}
        placeholder={placeholder}
        rows={rows || null}
        step={step}
        className={classNames("Input", {
          "Input--invalid": invalid
        })}
      >
        {children}
      </Field>

      <ErrorMessage
        name={name}
        component={ErrorMessageContainer}
      />
    </>
  );
};
