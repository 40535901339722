import "./Button.scss";

import classNames from "classnames";

export const Button = ({
  children,
  type,
  onClick,
  ownClassName,
  disabled,
  testId
}) => {
  const className = classNames("Button", {
    [`${ownClassName}`]: ownClassName
  });

  return (
    <button
      className={className}
      type={type || "button"}
      onClick={onClick}
      disabled={disabled || false}
      data-testid={testId}
    >
      {children}
    </button>
  );
};

export default Button;
