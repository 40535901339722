import {
  ErrorMessage,
  Field,
  useFormikContext
} from "formik";
import { useReviewContext } from "data/ReviewContext";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";

export const PropertyNotes = () => {
  const { handleSetValues } = useReviewContext();
  const { handleBlur } = useFormikContext();
  const FIELD_NAME = "propertyNotes";

  const onBlur = event => {
    handleBlur(event);
    handleSetValues(event.target.value, FIELD_NAME);
  };

  return (
    <div className="ReviewForm__section">
      <p className="ReviewForm__text">
        Please let us know of any particular issues you had
        with the property:
      </p>

      <Field
        className="ReviewForm__textarea"
        as="textarea"
        name={FIELD_NAME}
        rows="5"
        onBlur={onBlur}
        data-testid={FIELD_NAME}
      />

      <ErrorMessage
        name={FIELD_NAME}
        component={ErrorMessageContainer}
      />
    </div>
  );
};
