import "./StayDetailsPage.scss";

import {
  ContactDetails,
  PropertyAccess,
  PropertyInfo
} from "components/StayDetails";
import { CheckinCheckout } from "components/CheckinCheckout";
import { DetailsHeader } from "components/DetailsHeader";

export const StayDetailsPage = () => {
  return (
    <div className="StayDetailsPage">
      <DetailsHeader />
      <CheckinCheckout />
      <PropertyAccess />
      <PropertyInfo />
      <ContactDetails />
    </div>
  );
};
