import confetti from "assets/error/confetti.webp";
import confettiStatic from "assets/error/confetti-static.png";

export const Confetti = () => {
  function stopImage() {
    setTimeout(() => {
      document.getElementById("confetti-img").src =
        confettiStatic;
    }, 2450);
  }

  return (
    <img
      src={confetti}
      alt=""
      id="confetti-img"
      onLoad={stopImage}
      className="ReviewDone__image"
    />
  );
};
