import { Map } from "../../../Map";
import React, { useContext } from "react";
import {
  ACCESS_TYPE_KEYNEST,
  ACCESS_TYPE_RAILING_LOCKBOX,
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
} from "../../../../data/accessTypes";
import { AppContext } from "../../../../AppContext";

const isLockbox = accessType =>
  new Set([
    ACCESS_TYPE_RAILING_LOCKBOX,
    ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
  ]).has(accessType);

const getAccessCoordinates = (
  accessType,
  property,
  guestKey
) => {
  if (isLockbox(accessType)) {
    return {
      lat: property?.home_info?.location.lockbox_lat,
      lng: property?.home_info?.location.lockbox_lng
    };
  }
  if (accessType === ACCESS_TYPE_KEYNEST) {
    return {
      lat: guestKey?.keystore_location?.lat,
      lng: guestKey?.keystore_location?.lng
    };
  }
  return {
    lat: null,
    lng: null
  };
};

const CollectTheKeys = ({ instructions }) => {
  const {
    booking: { guest_key: guestKey, property }
  } = useContext(AppContext);
  const accessType =
    property?.home_info?.location?.access_type;

  const accessCenter = getAccessCoordinates(
    accessType,
    property,
    guestKey
  );

  return (
    <li className="PropertyAccess__item big-text">
      Collect the keys
      <div
        className={
          accessCenter.lat && accessCenter.lng
            ? "PropertyAccess__grid"
            : ""
        }
      >
        <div className="PropertyAccess__description">
          {instructions}
        </div>

        <Map
          lat={accessCenter.lat}
          lng={accessCenter.lng}
        />
      </div>
    </li>
  );
};

export default CollectTheKeys;
