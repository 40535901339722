export const ACCESS_TYPE_WALL_MOUNTED_LOCKBOX =
  "wall-mounted lockbox";
export const ACCESS_TYPE_RAILING_LOCKBOX =
  "railing lockbox";
export const ACCESS_TYPE_KEYNEST = "keynest";
export const ACCESS_TYPE_CONCIERGE = "concierge";
export const ACCESS_TYPE_NEARBY_MERCHANT =
  "nearby merchant";
export const ACCESS_TYPE_KEYLESS_DIGITAL_LOCK =
  "keyless digital lock";
export const ACCESS_TYPE_WELCOME_WIZARD = "welcome wizard";
export const ACCESS_TYPE_SALTO = "salto";
export const ACCESS_TYPE_KEYLESS_SMART_LOCK = "smart lock";
export const ACCESS_TYPE_KEYLESS_SMART_LOCK_INTERCOM =
  "smart lock intercom";
