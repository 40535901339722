const TEST_ENV = "test";
const DEVELOPMENT_ENV = "development";
const STAGING_ENV = "staging";
const PRODUCTION_ENV = "production";
const STAGING_URL = "guests.staging.houst.com";

const GOOGLE_API_KEY =
  "AIzaSyAT3pniXyhXz6sSewNX9Np8dsiijiuUN0Y";
const SENTRY_DSN_PROD =
  "https://a2f6a405afb34a6690341e9104a17140@o139147.ingest.sentry.io/6270875";

const environmentName = () => {
  if (window.location.host === STAGING_URL) {
    return STAGING_ENV;
  }

  // eslint-disable-next-line no-undef
  return process.env.NODE_ENV || DEVELOPMENT_ENV;
};

const API_HOST = {
  [TEST_ENV]: "http://localhost:3001",
  [DEVELOPMENT_ENV]: "http://localhost:3001",
  [STAGING_ENV]: "https://api.staging.houst.com",
  [PRODUCTION_ENV]: "https://api.houst.com"
}[environmentName()];

const SENTRY_DSN = {
  [TEST_ENV]: null,
  [DEVELOPMENT_ENV]: null,
  [STAGING_ENV]: SENTRY_DSN_PROD,
  [PRODUCTION_ENV]: SENTRY_DSN_PROD
}[environmentName()];

const STRIPE_KEY = {
  [TEST_ENV]: null,
  [DEVELOPMENT_ENV]: "pk_test_BvlIGxJgfy4R0o9zMzYYjWNn",
  [STAGING_ENV]: "pk_test_BvlIGxJgfy4R0o9zMzYYjWNn",
  [PRODUCTION_ENV]: "pk_live_XB5x9hIufcBDJNleCcSIxxI5"
}[environmentName()];

const config = {
  // eslint-disable-next-line no-undef
  ...process.env,
  API_HOST,
  GOOGLE_API_KEY,
  SENTRY_DSN,
  STRIPE_KEY
};

export default config;
