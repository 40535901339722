// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%22144%22 height%3D%22144%22 fill%3D%22none%22  xmlns%3Av%3D%22https%3A%2F%2Fvecta.io%2Fnano%22%3E%3Cg fill%3D%22%23f7f9fa%22 stroke%3D%22%231e1e24%22%3E%3Cpath d%3D%22M93.869 25.884H48.093c-3.889 0-7.042 3.153-7.042 7.042v88.031c0 3.889 3.153 7.042 7.042 7.042h45.776c3.89 0 7.042-3.153 7.042-7.042V32.927c0-3.889-3.153-7.042-7.042-7.042z%22 stroke-width%3D%223.521%22%2F%3E%3Cpath d%3D%22M70.981 120.958c2.431 0 4.402-1.971 4.402-4.402a4.4 4.4 0 1 0-8.803 0c0 2.431 1.971 4.402 4.401 4.402z%22 stroke-width%3D%221.761%22%2F%3E%3C%2Fg%3E%3Cg fill%3D%22%231e1e24%22%3E%3Cpath d%3D%22M81.985 32.927H59.977a1.32 1.32 0 1 0 0 2.641h22.008a1.32 1.32 0 1 0 0-2.641z%22%2F%3E%3Cpath d%3D%22M106.633 26.764h6.163a1.321 1.321 0 1 1 0 2.641h-6.163a1.32 1.32 0 1 1 0-2.641zm-5.281-11.443v6.162a1.321 1.321 0 0 1-2.641 0v-6.162a1.321 1.321 0 0 1 2.641 0zm3.134 6.171l4.357-4.357a1.321 1.321 0 1 1 1.868 1.867l-4.358 4.357a1.32 1.32 0 1 1-1.867-1.867z%22 fill-rule%3D%22evenodd%22%2F%3E%3C%2Fg%3E%3Cpath d%3D%22M93.429 56.255H48.533a1.76 1.76 0 0 0-1.761 1.761v9.683a1.76 1.76 0 0 0 1.761 1.761h44.896a1.76 1.76 0 0 0 1.761-1.761v-9.683a1.76 1.76 0 0 0-1.761-1.761z%22 fill%3D%22%23f7f9fa%22 stroke%3D%22%23a2a8b3%22 stroke-width%3D%22.88%22%2F%3E%3Cg fill-rule%3D%22evenodd%22%3E%3Cpath d%3D%22M51.614 62.857a1.76 1.76 0 1 0 0-3.521 1.76 1.76 0 1 0 0 3.521z%22 fill%3D%22%234fb312%22%2F%3E%3Cpath d%3D%22M56.896 60.216h25.529a.881.881 0 1 1 0 1.761H56.896a.881.881 0 1 1 0-1.761zm-.44 4.402h33.452a.44.44 0 1 1 0 .88H56.456a.44.44 0 1 1 0-.88z%22 fill%3D%22%23a2a8b3%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-crossDevice-MobileNotificationSent-icon{background-color:var(--osdk-color-background-icon);border-radius:50%}.onfido-sdk-ui-crossDevice-MobileNotificationSent-icon{height:9em;width:9em;background-size:contain;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");margin-bottom:2em;margin-left:auto;margin-right:auto}.onfido-sdk-ui-crossDevice-MobileNotificationSent-submessage{margin-bottom:0}.onfido-sdk-ui-crossDevice-MobileNotificationSent-boldMessage{margin-top:0;margin-bottom:2em;font-weight:600}.onfido-sdk-ui-crossDevice-MobileNotificationSent-cancel{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"icon": "onfido-sdk-ui-crossDevice-MobileNotificationSent-icon",
	"submessage": "onfido-sdk-ui-crossDevice-MobileNotificationSent-submessage",
	"boldMessage": "onfido-sdk-ui-crossDevice-MobileNotificationSent-boldMessage",
	"cancel": "onfido-sdk-ui-crossDevice-MobileNotificationSent-cancel"
};
export default ___CSS_LOADER_EXPORT___;
