import { InfoContainer } from "components/InfoContainer";

export const ExtraBedsMessage = ({ booking }) => {
  const {
    guest_can_make_extra_bed_request:
      guestCanMakeExtraBedRequest,
    property: { home_info: homeInfo }
  } = booking;

  if (guestCanMakeExtraBedRequest) {
    const linenStorageLocation =
      homeInfo?.cleaning?.spare_bedding_location;

    return (
      <InfoContainer>
        {booking.extraBedsRequired ? (
          <p>
            NB: You have confirmed that you need the use of
            my extra beds.{" "}
            {linenStorageLocation &&
              `You will be able to find spare linen ${linenStorageLocation}.`}{" "}
            If you need to change this requirement please do
            get in touch by email or via messenger.
          </p>
        ) : (
          <p>
            NB: You have confirmed that you do not need the
            use of my extra beds. If you need to change this
            requirement please do get in touch by email or
            via messenger.
          </p>
        )}
      </InfoContainer>
    );
  }

  return null;
};
