import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";

const WelcomeWizardInstructions = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      <p>
        One of our check-in agents will meet you at the
        apartment at your chosen check-in time.
      </p>

      <p>{location?.access_type_notes}</p>
    </>
  );
};

export default WelcomeWizardInstructions;
