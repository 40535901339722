import { ReactComponent as AppleLogo } from "assets/icons/logo-apple.svg";
import { ReactComponent as GoogleLogo } from "assets/icons/logo-google.svg";

export const PaymentRequestButton = ({
  paymentRequest,
  paymentVendor
}) => {
  const logo = {
    "appple-pay": <AppleLogo />,
    gpay: <GoogleLogo />
  };

  return (
    <button
      className="Tip__pay-button"
      type="button"
      onClick={paymentRequest?.show}
    >
      <span>
        {logo[paymentVendor]}
        Pay
      </span>
    </button>
  );
};
