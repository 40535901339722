import "./Checkout.scss";

import { useContext } from "react";
import { Accordion } from "components/Accordion";
import { AppContext } from "AppContext";
import {
  ACCESS_TYPE_CONCIERGE,
  ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
  ACCESS_TYPE_KEYNEST,
  ACCESS_TYPE_NEARBY_MERCHANT,
  ACCESS_TYPE_RAILING_LOCKBOX,
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX,
  ACCESS_TYPE_WELCOME_WIZARD
} from "data/accessTypes";
import { getCheckOutTime } from "utils/dateTime";

const LockboxInstructions = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  return (
    <p className="Checkout__instructions">
      Please put the keys back into the{" "}
      {property?.home_info?.location?.access_type} where you
      found them during check in. Check that the lock box is
      closed and locked before leaving.
    </p>
  );
};

const KeylessInstructions = () => (
  <p className="Checkout__instructions">
    Do not leave your keyless lock codes visible.
  </p>
);

const KeynestInstructions = () => (
  <p className="Checkout__instructions">
    Please remember to drop the keys off at the Keynest
    location where you collected them from. If there were
    any spare keys in the property, leave them where you
    found them.
  </p>
);

const WizardMerchantConciergeInstructions = () => (
  <p className="Checkout__instructions">
    If you are using physical keys, return them where you
    picked them up. Do not leave any key lockbox or keyless
    digital lock codes visible.
  </p>
);

const getInstructions = accessType => {
  switch (accessType) {
    case ACCESS_TYPE_WALL_MOUNTED_LOCKBOX:
    case ACCESS_TYPE_RAILING_LOCKBOX:
      return <LockboxInstructions />;
    case ACCESS_TYPE_KEYLESS_DIGITAL_LOCK:
      return <KeylessInstructions />;
    case ACCESS_TYPE_KEYNEST:
      return <KeynestInstructions />;
    case ACCESS_TYPE_CONCIERGE:
    case ACCESS_TYPE_WELCOME_WIZARD:
    case ACCESS_TYPE_NEARBY_MERCHANT:
      return <WizardMerchantConciergeInstructions />;
    default:
      return null;
  }
};

export const Checkout = () => {
  const { booking } = useContext(AppContext);
  const { property } = booking;

  const lockUpInstructions =
    property?.home_info?.location?.lock_up_instructions;
  const accessType =
    property?.home_info?.location?.access_type;
  const guestPenaltyChargeFormatted =
    property?.locality?.guest_penalty_charge_formatted;

  return (
    <Accordion title="Check-out">
      {property && (
        <p className="Checkout__instructions">
          Check-out is latest{" "}
          <strong>{getCheckOutTime(booking)}</strong>{" "}
          including all baggage.
        </p>
      )}

      {lockUpInstructions && (
        <p className="Checkout__instructions">
          When you leave, {lockUpInstructions}
        </p>
      )}

      {getInstructions(accessType)}

      {guestPenaltyChargeFormatted && (
        <p className="Checkout__instructions">
          Failure to follow check-out instructions will
          result in a{" "}
          <strong>
            minimum charge of {guestPenaltyChargeFormatted}
          </strong>
          .
        </p>
      )}

      <p className="Checkout__instructions">
        Please leave the home as you found it. If you can,
        please leave the towels and bathmats on the floor of
        the bathroom.
      </p>
    </Accordion>
  );
};
