// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ods-field-label>.onfido-sdk-ui-Capture-DateOfBirthInput-componentContainer{margin:4px 0 -4px}.onfido-sdk-ui-Capture-DateOfBirthInput-componentContainer{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row}.onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer{-webkit-box-sizing:border-box;box-sizing:border-box}.onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer+.onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer{margin-left:10px}.onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer.onfido-sdk-ui-Capture-DateOfBirthInput-small{width:60px}.onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer.onfido-sdk-ui-Capture-DateOfBirthInput-large{width:76px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentContainer": "onfido-sdk-ui-Capture-DateOfBirthInput-componentContainer",
	"inputContainer": "onfido-sdk-ui-Capture-DateOfBirthInput-inputContainer",
	"small": "onfido-sdk-ui-Capture-DateOfBirthInput-small",
	"large": "onfido-sdk-ui-Capture-DateOfBirthInput-large"
};
export default ___CSS_LOADER_EXPORT___;
