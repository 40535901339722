import { UnlockButton } from "../../../UnlockButton";
import React, { useContext } from "react";
import { AppContext } from "../../../../AppContext";

const DigitalIntercom = () => {
  const {
    booking: { can_access_smart_lock: canAccessSmartLock }
  } = useContext(AppContext);

  return (
    <li className="PropertyAccess__item big-text">
      <div className="PropertyAccess__wrapper">
        Once you are outside, press the button below to
        unlock the building entrance door.
        {canAccessSmartLock && <UnlockButton />}
      </div>
    </li>
  );
};

export default DigitalIntercom;
