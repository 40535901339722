// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CameraError-errorMessage{margin:0 2em}@media(max-width: 479px){.onfido-sdk-ui-CameraError-errorMessage{margin:0 1.5em}}@media(max-width: 359px){.onfido-sdk-ui-CameraError-errorMessage{margin:0 1em}}.onfido-sdk-ui-CameraError-errorContainer{left:0;position:absolute;right:0;top:0;z-index:4}.onfido-sdk-ui-CameraError-errorContainerType::before{content:\"\";display:block;height:100%;left:0;position:absolute;right:0;top:0;width:100%;z-index:1}.onfido-sdk-ui-CameraError-errorHasBackdrop{bottom:0}.onfido-sdk-ui-CameraError-errorHasBackdrop::before{content:\"\";display:block;height:100%;left:0;position:absolute;right:0;top:0;width:100%;z-index:1}.onfido-sdk-ui-CameraError-errorHasBackdrop::before{background-color:#1c1f21;opacity:.57}.onfido-sdk-ui-CameraError-errorMessage{top:3em;left:0;right:0;position:absolute;z-index:2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"errorMessage": "onfido-sdk-ui-CameraError-errorMessage",
	"errorContainer": "onfido-sdk-ui-CameraError-errorContainer",
	"errorContainerType": "onfido-sdk-ui-CameraError-errorContainerType",
	"errorHasBackdrop": "onfido-sdk-ui-CameraError-errorHasBackdrop"
};
export default ___CSS_LOADER_EXPORT___;
