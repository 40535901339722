import "./ReviewTip.scss";
import { useState, useMemo, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import loader from "assets/loader.webp";
import { getCurrencySymbol } from "utils/format";
import config from "config";
import { Button } from "components/Button";
import { PaymentRequestButton } from "components/Review/PaymentRequestButton";
import { SuggestedTipAmounts } from "components/Review/SuggestedTipAmounts";
import { useAppContext } from "../AppContext";
import { useReviewContext } from "../data/ReviewContext";
import { useStripePayments } from "../components/Review/useStripePayments";
import { ReviewTipCustomAmountForm } from "../components/ReviewTipCustomAmountForm";

const StripeSetup = ({ children }) => {
  const { cleanerStripeAccount } = useReviewContext();

  const stripePromise = useMemo(
    () =>
      loadStripe(config.STRIPE_KEY, {
        stripeAccount: cleanerStripeAccount
      }),
    [cleanerStripeAccount]
  );

  return (
    <Elements stripe={stripePromise}>{children}</Elements>
  );
};

export const ReviewTip = () => {
  return (
    <StripeSetup>
      <ReviewTipInternal />
    </StripeSetup>
  );
};

const ReviewTipInternal = () => {
  const { booking } = useAppContext();
  const { cleanerName } = useReviewContext();

  if (!booking.property) {
    return null;
  }

  const {
    paymentVendor,
    paymentRequest,
    walletsUnavailable
  } = useStripePayments();

  const {
    handleSendTipAmount,
    tipAmount,
    tipError,
    setTipAmount,
    setTipCompleted,
    formError
  } = useReviewContext();
  const [viewCustomAmount, setViewCustomAmount] =
    useState(false);

  const handleTipSelection = option => () => {
    Sentry.captureMessage(
      `Tips: chosen ${option} as amount`
    );
    setTipAmount(option);
  };

  const handleCustomAmountClick = () => {
    Sentry.captureMessage("Tips: showing custom tip form");
    setViewCustomAmount(true);
  };

  const handleSkip = () => {
    Sentry.captureMessage("Tips: user skipped tips");
    handleSendTipAmount(0);
    setTipCompleted(true);
  };

  // Show thank you page if there's no wallet to use
  useEffect(() => {
    if (walletsUnavailable) {
      Sentry.captureMessage("Tips: no wallet configured");
      setTipCompleted(true);
    }
  }, [walletsUnavailable]);

  if (paymentVendor) {
    const currency = booking.property.locality?.currency;

    return (
      <div className="Tip">
        <h4 className="Tip__header">
          Glad you had a great experience!
          <br />
          {`Would you like to leave a tip for ${cleanerName}, your cleaner?`}
        </h4>
        <p className="Tip__text">
          Full amount of the tip after card processing fees
          goes to the cleaner.
          <br />
          Please don&apos;t leave a cash tip.
        </p>
        {tipError && (
          <p className="Tip__error">
            An error occurred, please try again later.
          </p>
        )}

        {viewCustomAmount ? (
          <ReviewTipCustomAmountForm
            currencySymbol={getCurrencySymbol(currency)}
          />
        ) : (
          <SuggestedTipAmounts
            tipAmount={tipAmount}
            currency={currency}
            handleAmountButtonClick={handleTipSelection}
            handleCustomAmountClick={
              handleCustomAmountClick
            }
          />
        )}

        {!formError && paymentRequest && (
          <PaymentRequestButton
            paymentRequest={paymentRequest}
            paymentVendor={paymentVendor}
          />
        )}

        <Button
          ownClassName="Tip__skip-button"
          onClick={handleSkip}
        >
          Skip
        </Button>
      </div>
    );
  }

  return (
    <div className="loader-wrapper">
      <img src={loader} alt="Loader" className="loader" />
    </div>
  );
};
