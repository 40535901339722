import "./HouseRules.scss";

import { useAppContext } from "AppContext";

export const HouseRules = () => {
  const { booking } = useAppContext();

  const {
    property: { locality, home_info: homeInfo }
  } = booking;

  const penaltyChargeNotice =
    locality && locality.guest_penalty_charge_formatted
      ? `A minimum fee of ${locality.guest_penalty_charge_formatted} will be charged for lockouts, late check outs or failure to leave the keys in the lockbox/keynest`
      : "Late checkouts will be charged a fee";

  return (
    <div className="HouseRules">
      <h2 className="header">House rules</h2>
      <p className="HouseRules__content">
        Smoking is not allowed
      </p>
      <p className="HouseRules__content">
        No music or loud noise after 10:00pm
      </p>
      <p className="HouseRules__content">
        {penaltyChargeNotice}
      </p>
      <p className="HouseRules__content">
        In the event of a party or a gathering, you will be
        charged for any costs related to damages and
        additional cleaning
      </p>

      {homeInfo?.house_rules && (
        <p className="HouseRules__content">
          {homeInfo.house_rules}
        </p>
      )}
    </div>
  );
};
