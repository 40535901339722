import "./ReviewDone.scss";

import { Confetti } from "components/Confetti";

export const ReviewDone = () => {
  return (
    <div className="ReviewDone">
      <Confetti />

      <p className="ReviewDone__text">
        Thank you for your feedback!
      </p>
    </div>
  );
};
