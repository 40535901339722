export const getCurrencySymbol = currency => {
  switch (currency) {
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "AED":
      return "AED";
    case "AUD":
      return "A$";
    case "NZD":
      return "NZ$";
    case "ZAR":
      return "R";
    default:
      return "";
  }
};

export const formatCurrency = (currency, value) => {
  switch (currency) {
    case "GBP":
    case "EUR":
    case "NZD":
    case "AUD":
    case "ZAR":
      return `${getCurrencySymbol(currency)}${value}`;
    case "AED":
      return `${value} ${getCurrencySymbol(currency)}`;
    default:
      return value;
  }
};
