import "./Accordion.scss";

import { useState } from "react";
import classNames from "classnames";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";

export const Accordion = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classNames([
        "accordion",
        { "accordion--open": isOpen }
      ])}
    >
      <button
        className="accordion__title big-text"
        onClick={toggle}
      >
        <ChevronIcon className="accordion__icon" />

        <span className="accordion__title-text">
          {title}
        </span>
      </button>

      <div className="accordion__content">{children}</div>
    </div>
  );
};
