import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import GoogleStore from "../../../../../assets/badge_google_store.png";
import AppleStore from "../../../../../assets/badge_app_store.png";

const APP_STORE_URL =
  "https://apps.apple.com/us/app/justin-mobile/id960998088";
const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.saltosystems.justin";

const SaltoInstructions = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      <p>
        The apartment has a keyless lock which can be opened
        via the “JustIn” mobile app. Please download the app
        on your smartphone. Let us know once this is done
        and please send us the mobile number that is
        associated to this device.
      </p>

      {location?.access_map && (
        <p>
          <strong>
            Please find a detailed guide with photos for
            accessing the property{" "}
            <a
              href={location.access_map}
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </strong>
        </p>
      )}

      <p>{location?.access_type_notes}</p>

      <p>
        <strong>
          Please note, if we don&apos;t receive the mobile
          numbers for all guests that have been registered,
          we won&apos;t be able to approve your device to
          access the property. Thank you!
        </strong>
      </p>
      <div className="PropertyAccess__links">
        <a
          className="PropertyAccess__link"
          target="_blank"
          rel="noopener noreferrer"
          href={PLAY_STORE_URL}
        >
          <img
            alt="Download from Google Play"
            src={GoogleStore}
            className="PropertyAccess__link-image"
          />
        </a>
        <a
          className="PropertyAccess__link"
          target="_blank"
          rel="noopener noreferrer"
          href={APP_STORE_URL}
        >
          <img
            alt="Download from AppStore"
            src={AppleStore}
            className="PropertyAccess__link-image"
          />
        </a>
      </div>
    </>
  );
};

export default SaltoInstructions;
