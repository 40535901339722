import "./Stars.scss";
import { Field } from "formik";
import classNames from "classnames";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";

export const Star = ({
  id,
  index,
  label,
  name,
  onChange,
  selected,
  value
}) => {
  const isFilled = index < selected;
  return (
    <div
      className={classNames("Star", {
        "Star--filled": isFilled
      })}
      data-testid={`${name}-${value}`}
    >
      <Field
        className="Star__input"
        id={id}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
      />
      <label className="Star__label" htmlFor={id}>
        {label}
        <StarIcon />
      </label>
    </div>
  );
};
