import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";

const NearbyMerchantInstructions = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      <p>
        Access to the home can be obtained from a{" "}
        {location?.access_type}
      </p>

      {location?.access_map && (
        <p>
          <strong>
            Please find a detailed guide with photos for
            accessing the property{" "}
            <a
              href={location.access_map}
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </strong>
        </p>
      )}

      {location?.lockbox_code ? (
        <p>
          The code to the {location?.access_type} is{" "}
          {location?.lockbox_code}. Do not leave any codes
          visible.
        </p>
      ) : (
        <p>
          You will receive your code 48 hours before your
          arrival date.
        </p>
      )}

      <p>{location?.access_type_notes}</p>
    </>
  );
};

export default NearbyMerchantInstructions;
