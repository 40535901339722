import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";

const Concierge = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      <p>
        Access to the home can be obtained from a{" "}
        {location?.access_type}.
      </p>
      <p>{location?.access_type_notes}</p>
    </>
  );
};

export default Concierge;
