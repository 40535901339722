import { Map } from "../../../Map";
import React, { useContext } from "react";
import { AppContext } from "../../../../AppContext";

const FindTheProperty = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const propertyLocationNotes =
    property?.home_info?.location?.property_location_notes;

  return (
    <li className="PropertyAccess__item big-text">
      Find the property
      <div
        className={
          propertyLocationNotes
            ? "PropertyAccess__grid"
            : "PropertyAccess__wrapper"
        }
      >
        {propertyLocationNotes && (
          <div className="PropertyAccess__description">
            {propertyLocationNotes}
          </div>
        )}

        <Map
          lat={property?.home_info?.location?.property_lat}
          lng={property?.home_info?.location?.property_lng}
        />
      </div>
    </li>
  );
};

export default FindTheProperty;
