import { useFormikContext } from "formik";
import { useReviewContext } from "data/ReviewContext";
import { Star } from "components/Review/Fields";

const options = [1, 2, 3, 4, 5];

export const PropertyRating = () => {
  const { handleChange, values } = useFormikContext();

  const { handleSetPropertyRating } = useReviewContext();

  const labels = ["Terrible", "Bad", "Ok", "Good", "Great"];

  const onChange = event => {
    handleChange(event);
    handleSetPropertyRating(event.target.value);
  };

  return (
    <div className="ReviewForm__section">
      <p className="ReviewForm__stars-text">
        How would you rate the general condition of the
        apartment?
      </p>

      <div className="ReviewForm__stars">
        {options.map((el, index) => (
          <Star
            id={`propertyRating=${el}`}
            index={index}
            key={el}
            label={el}
            name="propertyRating"
            value={el.toString()}
            onChange={onChange}
            selected={values.propertyRating}
          />
        ))}
      </div>

      <p className="ReviewForm__description">
        {labels[values.propertyRating - 1]}
      </p>
    </div>
  );
};
