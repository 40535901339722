import { useState } from "react";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { PopUp } from "components/PopUp";

export const LabelWithInfo = ({
  htmlFor,
  label,
  infoText
}) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  return (
    <>
      {showPopUp && (
        <PopUp handleClose={togglePopUp}>{infoText}</PopUp>
      )}
      <label
        htmlFor={htmlFor}
        className="Input__label withIcon"
        onClick={togglePopUp}
        role="presentation"
        onKeyUp={togglePopUp}
      >
        {label}
        <InfoIcon />
      </label>
    </>
  );
};
