import { Accordion } from "components/Accordion";
import { AmenitiesList } from "components/StayDetails/Sections/AmenitiesList";

export const Amenities = ({ property }) => {
  return (
    <Accordion title="Amenities">
      <ul className="PropertyInformation__list">
        <AmenitiesList propertyId={property.id} />
      </ul>
    </Accordion>
  );
};
