import { Accordion } from "components/Accordion";

export const NotesFromHost = ({ property }) => {
  const hostExtraInstructions =
    property?.home_info?.host_extra_instructions;

  if (hostExtraInstructions) {
    return (
      <Accordion title="Other notes from your host">
        <p>{hostExtraInstructions}</p>
      </Accordion>
    );
  }

  return null;
};
