import { Accordion } from "components/Accordion";
import { DescriptionItem } from "components/DescriptionItem";

export const WiFi = ({ property }) => {
  const networkName =
    property?.home_info?.wifi_network_name;
  const password = property?.home_info?.wifi_password;

  if (networkName && password) {
    return (
      <Accordion title="Wi-Fi">
        <DescriptionItem
          title="Wi-Fi network name"
          content={networkName}
        />
        <DescriptionItem
          title="Wi-Fi password"
          content={password}
        />
      </Accordion>
    );
  }
  return null;
};
