import "./PopUp.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

export const PopUp = ({ children, handleClose }) => {
  return (
    <div className="PopUp">
      <div className="PopUp__content">
        <button
          type="button"
          onClick={handleClose}
          className="PopUp__close"
        >
          <CloseIcon />
        </button>
        {children}
      </div>
    </div>
  );
};
