import { useEffect } from "react";
import classNames from "classnames";
import * as Sentry from "@sentry/react";

import { Button } from "components/Button";
import { formatCurrency } from "utils/format";

export const SuggestedTipAmounts = ({
  handleAmountButtonClick,
  tipAmount,
  currency,
  handleCustomAmountClick
}) => {
  const TIP_OPTIONS = [2, 5, 10];

  useEffect(() => {
    Sentry.captureMessage(
      "Tips: showing suggested tip amounts"
    );
  }, []);

  return (
    <>
      <ul className="Tip__options">
        {TIP_OPTIONS.map(option => (
          <li key={option} className="Tip__option">
            <button
              onClick={handleAmountButtonClick(option)}
              className={classNames("Tip__button", {
                "Tip__button--selected":
                  tipAmount === option
              })}
            >
              {formatCurrency(currency, option)}
            </button>
          </li>
        ))}
      </ul>

      <Button
        ownClassName="Tip__other-amount-button"
        onClick={handleCustomAmountClick}
      >
        Other amount
      </Button>
    </>
  );
};
