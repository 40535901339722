import "./ContactDetails.scss";

import { useContext } from "react";
import { AppContext } from "AppContext";

export const ContactDetails = () => {
  const {
    booking: { contactNumber }
  } = useContext(AppContext);

  if (!contactNumber) {
    return null;
  }

  return (
    <p className="ContactDetails">
      You should find all the information you need on this
      page. If you cannot find what you need in these
      instructions or in case of an emergency you may
      contact us on this number:{" "}
      <a href={`tel:${contactNumber}`}>{contactNumber}</a>
    </p>
  );
};
