import { h } from 'preact'

import {
  isDesktop,
  isHybrid,
  addDeviceRelatedProperties,
  isAndroidUa,
} from '~utils'
import { validateFile } from '~utils/file'
import { DOCUMENT_CAPTURE_LOCALES_MAPPING } from '~utils/localesMapping'
import { randomId } from '~utils/string'

import { appendToTracking, trackException } from '../../Tracker'
import { useLocales } from '~core/localisation'
import Uploader from '../Uploader'
import CustomFileInput from '../CustomFileInput'
import { getDocumentTypeGroup } from '../DocumentSelector/documentTypes'
import FallbackButton from '../Button/FallbackButton'
import theme from '../Theme/style.scss'

import withCrossDeviceWhenNoCamera from './withCrossDeviceWhenNoCamera'

import type { DocumentSides, ImageResizeInfo } from '~types/commons'
import type {
  TrackEventBeforeMountCallback,
  WithCaptureVariantProps,
} from '~types/hocs'
import type { DocumentCapture } from '~types/redux'
import type {
  HandleCaptureProp,
  HandleDocMultiFrameCaptureProp,
  RenderFallbackProp,
  StepComponentDocumentProps,
} from '~types/routers'
import type { DocumentTypes } from '~types/steps'
import { LazyDocumentAuto } from '../DocumentAuto/Lazy'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import { documentAutoConfiguration } from 'components/DocumentAuto/utils'
import { getGenericDocumentAnalytics } from 'Tracker/documentTracking'

const EXCEPTIONS = {
  DOC_TYPE_NOT_PROVIDED: 'documentType was not provided',
  CAPTURE_SIDE_NOT_PROVIDED: 'Capture side was not provided',
}

const getDocumentType = (documentType?: DocumentTypes): DocumentTypes => {
  if (documentType) {
    return documentType
  }

  trackException(EXCEPTIONS.DOC_TYPE_NOT_PROVIDED)
  throw new Error(EXCEPTIONS.DOC_TYPE_NOT_PROVIDED)
}

const getSide = (side?: DocumentSides): DocumentSides => {
  if (side) {
    return side
  }

  trackException(EXCEPTIONS.CAPTURE_SIDE_NOT_PROVIDED)
  throw new Error(EXCEPTIONS.CAPTURE_SIDE_NOT_PROVIDED)
}

type Props = StepComponentDocumentProps & WithCaptureVariantProps

const Document = (props: Props) => {
  const { translate } = useLocales()
  const sdkConfiguration = useSdkConfigurationService()

  const handlePhotoCapture: HandleCaptureProp = (payload) => {
    const { actions, documentType, mobileFlow, nextStep, side } = props

    const documentCaptureData: DocumentCapture = {
      ...payload,
      documentType: getDocumentType(documentType),
      id: payload.id || randomId(),
      method: 'document',
      sdkMetadata: addDeviceRelatedProperties(payload.sdkMetadata, mobileFlow),
      side,
      variant: 'standard',
    }
    actions.createCapture(documentCaptureData)

    nextStep()
  }

  const handleMultiFrameCapture: HandleDocMultiFrameCaptureProp = (payload) => {
    const { actions, documentType, mobileFlow, side, nextStep } = props
    const { video, photo } = payload

    const baseData: Omit<DocumentCapture, 'blob' | 'id'> = {
      documentType: getDocumentType(documentType),
      method: 'document',
      sdkMetadata: addDeviceRelatedProperties(
        video?.sdkMetadata || {},
        mobileFlow
      ),
    }

    actions.createCapture({
      ...photo,
      ...baseData,
      id: randomId(),
      side,
    })

    actions.createCapture({
      ...video,
      ...baseData,
      id: randomId(),
      variant: 'video',
      side,
    })

    nextStep()
  }

  const handleUpload = (blob: Blob, imageResizeInfo?: ImageResizeInfo) =>
    handlePhotoCapture({
      blob,
      sdkMetadata: { captureMethod: 'html5', imageResizeInfo },
    })

  const handleError = () => {
    const { actions, side, requestedVariant: variant } = props
    actions.deleteCapture({ method: 'document', side, variant })
  }

  const handleFileSelected = (file: File) =>
    validateFile(file, handleUpload, handleError)

  const renderUploadFallback: RenderFallbackProp = ({ text }, callback) => (
    <CustomFileInput
      className={theme.warningFallbackButton}
      onChange={handleFileSelected}
      onClick={callback}
      accept="image/*"
      capture="environment"
    >
      {text}
    </CustomFileInput>
  )

  const renderCrossDeviceFallback: RenderFallbackProp = ({ text }) => {
    const { changeFlowTo } = props
    return (
      <FallbackButton
        text={text}
        onClick={() => changeFlowTo('crossDeviceSteps')}
      />
    )
  }

  const { hasCamera, imageQualityRetries, trackScreen } = props

  const documentType = getDocumentType(props.documentType)

  const renderFallback = isDesktop
    ? renderCrossDeviceFallback
    : renderUploadFallback

  const side = getSide(props.side)

  const title = translate(
    DOCUMENT_CAPTURE_LOCALES_MAPPING[documentType][side]?.title || ''
  )

  const trackEvent: TrackEventBeforeMountCallback = () => ({
    event: 'DOCUMENT_CAPTURE',
    properties: {
      country_code: props.idDocumentIssuingCountry?.country_alpha2,
      document_type: props.documentType,
      ...getGenericDocumentAnalytics(props),
    },
  })

  // When to show the Cross device flow / Uploader
  if (
    (isDesktop && !isHybrid) ||
    !hasCamera ||
    !sdkConfiguration.document_capture?.enable_js_camera_doc_capture
  ) {
    // return early
    // Different upload types show different icons
    // return the right icon name for document
    // For document, the upload can be 'identity' or 'proof_of_address'

    // TODO: why do we try to get the document type group from the document type again. It would make much more sense
    //  to have the overall properties know about the current step and then just take the upload type from the current step

    const uploadType = getDocumentTypeGroup(documentType)
    const instructions = translate(
      DOCUMENT_CAPTURE_LOCALES_MAPPING[documentType][side]?.body || ''
    )

    const disableCrossDevice =
      !!sdkConfiguration.document_capture?.allow_disabling_cross_device &&
      props.disableCrossDevice

    const propsWithErrorHandling = {
      ...props,
      forceCrossDevice:
        (!disableCrossDevice && props.forceCrossDevice) ?? false,
      onError: handleError,
    }

    return (
      <Uploader
        {...propsWithErrorHandling}
        disableCrossDevice={disableCrossDevice}
        uploadType={uploadType}
        onUpload={handleUpload}
        title={title}
        instructions={instructions}
        pageId={'DocumentUploader'}
        countryCode={props.idDocumentIssuingCountry?.country_alpha2}
        trackEventBeforeMount={trackEvent}
      />
    )
  }

  if (!documentType) {
    trackException(EXCEPTIONS.DOC_TYPE_NOT_PROVIDED)
    throw new Error('documentType not provided')
  }

  const {
    isMultiFrameCapture,
    isAutoCapture,
    autoCaptureTimeoutMilliseconds,
  } = documentAutoConfiguration(
    sdkConfiguration,
    documentType,
    props.idDocumentIssuingCountry
  )

  return (
    <LazyDocumentAuto
      buttonType={'video'}
      documentType={documentType}
      renderFallback={renderFallback}
      isUploadFallbackDisabled={
        !sdkConfiguration.document_capture?.enable_native_camera_fallback
      }
      imageQualityRetries={props.imageQualityRetries}
      onCapture={handlePhotoCapture}
      onMultiFrameCapture={handleMultiFrameCapture}
      trackScreen={trackScreen}
      side={side}
      isAutoCapture={isAutoCapture}
      isMultiFrameCapture={isMultiFrameCapture}
      autoCaptureTimeoutMilliseconds={autoCaptureTimeoutMilliseconds}
      documentAnalytics={{
        count_attempt: imageQualityRetries,
        country_code: props.idDocumentIssuingCountry?.country_alpha3 || '',
        document_side: side,
        document_type: documentType,
        max_retry_count: sdkConfiguration.document_capture?.max_total_retries,
        platform: isAndroidUa(navigator.userAgent) ? 'android' : 'ios', // maybe not exact but that's how we use this in DocumentAuto
        user_agent: navigator.userAgent,
        is_multiframe_capture: !!isMultiFrameCapture,
        is_autocapture_enabled: !!isAutoCapture,
      }}
    />
  )
}

export default appendToTracking(withCrossDeviceWhenNoCamera(Document))
