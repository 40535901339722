import "./App.scss";

import { Routes, Route } from "react-router-dom";
import { Error } from "scenes/Error";
import { Header } from "components/Header";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Instructions } from "scenes/Instructions";
import { Review } from "scenes/Review";
import { Verification } from "scenes/verification/Verification";
import { HomeInfoPreview } from "scenes/HomeInfoPreview";

function App() {
  return (
    <div className="app-background">
      <div className="App">
        <Header />
        <div className="App__container">
          <ErrorBoundary>
            <Routes>
              <Route
                path="/i/:bookingToken"
                element={<Instructions />}
              />
              <Route
                path="/r/:bookingToken"
                element={<Review />}
              />
              <Route
                path="/v/:bookingToken"
                element={<Verification />}
              />
              <Route
                path="/p/:bookingToken"
                element={<HomeInfoPreview />}
              />
              <Route path="/error" element={<Error />} />
            </Routes>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default App;
