import "./Verification.scss";

import { useMemo } from "react";
import {
  useAppContext,
  AppContextProvider
} from "AppContext";
import loader from "assets/loader.webp";
import { DetailsHeader } from "components/DetailsHeader";
import { CheckinCheckout } from "components/CheckinCheckout";
import { OnfidoWidget } from "components/OnfidoWidget";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { GuestDetailsForm } from "./GuestDetailsForm";
import { Done } from "./Done";

const STEP_VERIFICATION = "verification";
const STEP_GUEST_DETAILS = "guest_details";
const STEP_DONE = "done";

const HEADER_CONTENT = {
  [STEP_VERIFICATION]:
    "As this booking is for exclusive use of a private residence, we need to quickly and securely verify your identity. By completing the booking verification you agree to our cancellation policy.",
  [STEP_GUEST_DETAILS]:
    "Simply fill in and confirm the details below to finish checking in (all fields are required).",
  [STEP_DONE]: null
};

const VerificationContainer = () => {
  const { booking, bookingLoading } = useAppContext();

  if (bookingLoading) {
    return (
      <img src={loader} alt="Loader" className="loader" />
    );
  }

  if (booking && booking.property) {
    const { verification_step: verificationStep } = booking;

    const step = useMemo(() => {
      switch (verificationStep) {
        case STEP_VERIFICATION:
          return <OnfidoWidget />;
        case STEP_GUEST_DETAILS:
          return <GuestDetailsForm />;
        case STEP_DONE:
          return <Done />;
        default:
          return null;
      }
    }, [verificationStep]);

    const headerContent = useMemo(
      () => HEADER_CONTENT[verificationStep],
      [verificationStep]
    );

    return (
      <div>
        <DetailsHeader />

        <CheckinCheckout />

        {headerContent && (
          <div className="yellow-header">
            <p>{headerContent}</p>

            <p className="verify-alert">
              <AlertIcon />
              Identity not verified will lead to your
              reservation being cancelled.
            </p>
          </div>
        )}

        <div className="Verification">{step}</div>
      </div>
    );
  }

  return null;
};

export const Verification = () => (
  <AppContextProvider>
    <VerificationContainer />
  </AppContextProvider>
);
