import "./PropertyInfo.scss";
import {
  GettingAround,
  WiFi,
  NotesFromHost,
  TransportAndNeighbourhood,
  Checkout,
  ContactInfo,
  Parking,
  Amenities
} from "components/StayDetails/Sections";
import { ExtraBedsMessage } from "components/StayDetails/ExtraBedsMessage";
import { useAppContext } from "AppContext";

export const PropertyInfo = () => {
  const {
    booking: { property },
    booking
  } = useAppContext();

  return (
    <section className="PropertyInfo">
      <ExtraBedsMessage booking={booking} />

      <h2 className="PropertyInfo__heading header">
        Where you&apos;re staying
      </h2>
      <GettingAround property={property} />
      <Parking property={property} />
      <TransportAndNeighbourhood property={property} />
      <Amenities property={property} />
      <WiFi property={property} />
      <Checkout />
      <NotesFromHost property={property} />
      <ContactInfo />
    </section>
  );
};
