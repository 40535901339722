import "./PropertyInformation.scss";
import { useEffect, useState, useCallback } from "react";

import { ReactComponent as BabyCarriageIcon } from "assets/icons/baby-carriage.svg";
import { ReactComponent as BabyIcon } from "assets/icons/baby.svg";
import { ReactComponent as BeachIcon } from "assets/icons/beach.svg";
import { ReactComponent as BedFilledIcon } from "assets/icons/bed-filled.svg";
import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { ReactComponent as BikeIcon } from "assets/icons/bike.svg";
import { ReactComponent as BottleIcon } from "assets/icons/bottle.svg";
import { ReactComponent as BreadIcon } from "assets/icons/bread.svg";
import { ReactComponent as BubbleIcon } from "assets/icons/bubble.svg";
import { ReactComponent as BugOffIcon } from "assets/icons/bug-off.svg";
import { ReactComponent as BulbIcon } from "assets/icons/bulb.svg";
import { ReactComponent as CakeIcon } from "assets/icons/cake.svg";
import { ReactComponent as CampfireIcon } from "assets/icons/campfire.svg";
import { ReactComponent as ChargingPileIcon } from "assets/icons/charging-pile.svg";
import { ReactComponent as ChefHatIcon } from "assets/icons/chef-hat.svg";
import { ReactComponent as CoffeeIcon } from "assets/icons/coffee.svg";
import { ReactComponent as DisabledIcon } from "assets/icons/disabled.svg";
import { ReactComponent as DiscIcon } from "assets/icons/disc.svg";
import { ReactComponent as DishIcon } from "assets/icons/dishes.svg";
import { ReactComponent as DoorEnterIcon } from "assets/icons/door-enter.svg";
import { ReactComponent as DoorIcon } from "assets/icons/door.svg";
import { ReactComponent as DropIcon } from "assets/icons/drop.svg";
import { ReactComponent as DropletsIcon } from "assets/icons/droplets.svg";
import { ReactComponent as EggFriedIcon } from "assets/icons/egg-fried.svg";
import { ReactComponent as FanIcon } from "assets/icons/propeller.svg";
import { ReactComponent as FenceIcon } from "assets/icons/fence.svg";
import { ReactComponent as FireExtinguisherIcon } from "assets/icons/fire_extinguisher.svg";
import { ReactComponent as FirstAidKitIcon } from "assets/icons/first-aid-kit.svg";
import { ReactComponent as FlipFlopsIcon } from "assets/icons/flip-flops.svg";
import { ReactComponent as FridgeIcon } from "assets/icons/fridge.svg";
import { ReactComponent as FrostIcon } from "assets/icons/frost.svg";
import { ReactComponent as GrillIcon } from "assets/icons/grill.svg";
import { ReactComponent as GymIcon } from "assets/icons/gym.svg";
import { ReactComponent as HandGrabIcon } from "assets/icons/hand-grab.svg";
import { ReactComponent as HangerIcon } from "assets/icons/hanger.svg";
import { ReactComponent as HeaterIcon } from "assets/icons/heating.svg";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as HorseToyIcon } from "assets/icons/horse-toy.svg";
import { ReactComponent as IronIcon } from "assets/icons/iron.svg";
import { ReactComponent as JumpRopeIcon } from "assets/icons/jump-rope.svg";
import { ReactComponent as KayakIcon } from "assets/icons/kayak.svg";
import { ReactComponent as KettleIcon } from "assets/icons/kettle.svg";
import { ReactComponent as KitchenToolsIcon } from "assets/icons/tools-kitchen.svg";
import { ReactComponent as LoadSheddingIcon } from "assets/icons/bolt.svg";
import { ReactComponent as LockSquareIcon } from "assets/icons/lock-square.svg";
import { ReactComponent as LuggageIcon } from "assets/icons/luggage.svg";
import { ReactComponent as MeepleIcon } from "assets/icons/meeple.svg";
import { ReactComponent as MicrowaveIcon } from "assets/icons/microwave.svg";
import { ReactComponent as OtherIcon } from "assets/icons/sparkle.svg";
import { ReactComponent as OutletIcon } from "assets/icons/outlet.svg";
import { ReactComponent as PadIcon } from "assets/icons/pad.svg";
import { ReactComponent as ParkingIcon } from "assets/icons/parking.svg";
import { ReactComponent as PianoIcon } from "assets/icons/piano.svg";
import { ReactComponent as PingPongIcon } from "assets/icons/ping-pong.svg";
import { ReactComponent as PoolIcon } from "assets/icons/pool.svg";
import { ReactComponent as RippleIcon } from "assets/icons/ripple.svg";
import { ReactComponent as SailboatIcon } from "assets/icons/sailboat.svg";
import { ReactComponent as SensorIcon } from "assets/icons/sensor.svg";
import { ReactComponent as ShirtIcon } from "assets/icons/shirt.svg";
import { ReactComponent as SnowflakeIcon } from "assets/icons/snowflake.svg";
import { ReactComponent as SockIcon } from "assets/icons/sock.svg";
import { ReactComponent as SoundSystemIcon } from "assets/icons/device-speaker.svg";
import { ReactComponent as SportBillardIcon } from "assets/icons/sport-billard.svg";
import { ReactComponent as SprayIcon } from "assets/icons/spray.svg";
import { ReactComponent as SteamIcon } from "assets/icons/steam.svg";
import { ReactComponent as StoveIcon } from "assets/icons/stove.svg";
import { ReactComponent as SunIcon } from "assets/icons/sun-off.svg";
import { ReactComponent as SunWindIcon } from "assets/icons/sun-wind.svg";
import { ReactComponent as ToolsKitchen2Icon } from "assets/icons/tools-kitchen-2.svg";
import { ReactComponent as TreesIcon } from "assets/icons/trees.svg";
import { ReactComponent as TvIcon } from "assets/icons/tv.svg";
import { ReactComponent as WashIcon } from "assets/icons/wash.svg";
import { ReactComponent as WashMachineIcon } from "assets/icons/wash-machine.svg";
import { ReactComponent as Wifi2Icon } from "assets/icons/wifi-2.svg";
import { ReactComponent as WorkspaceIcon } from "assets/icons/device-laptop.svg";

import api from "services/api";

const amenityIcon = {
  air_conditioning: FrostIcon,
  baby_cot: BabyIcon,
  baby_monitor: SensorIcon,
  babysitter_recommendations: BabyCarriageIcon,
  baking_sheet: CakeIcon,
  barbecue: GrillIcon,
  beach_access: BeachIcon,
  beach_essentials: BeachIcon,
  bed_linens: BedFilledIcon,
  bicycle: BikeIcon,
  bidet: WashIcon,
  bikes_for_rent: BikeIcon,
  blackout_blinds: SunIcon,
  blender: KitchenToolsIcon,
  board_games: MeepleIcon,
  boat_slip: SailboatIcon,
  body_soap: BottleIcon,
  boiler: HeaterIcon,
  breakfast: EggFriedIcon,
  carbon_monoxide_alarm: BellIcon,
  ceiling_fan: FanIcon,
  ceiling_hoist: HandGrabIcon,
  childrens_books_and_toys: HorseToyIcon,
  cleaning_before_checkout: SprayIcon,
  cleaning_products: SprayIcon,
  clothes_drying_rack: ShirtIcon,
  coffee_machine: CoffeeIcon,
  conditioner: BottleIcon,
  cooking_basics: ChefHatIcon,
  designated_workspace: WorkspaceIcon,
  dining_table: ToolsKitchen2Icon,
  disabled_parking_spot: ParkingIcon,
  dishes_and_silverware: ToolsKitchen2Icon,
  dishwasher: DishIcon,
  dryer: SockIcon,
  ev_charger: ChargingPileIcon,
  exercise_equipment: JumpRopeIcon,
  fire_extinguisher: FireExtinguisherIcon,
  fire_pit: CampfireIcon,
  fireplace: CampfireIcon,
  first_aid_kit: FirstAidKitIcon,
  flat_smooth_pathway_to_front_door: DisabledIcon,
  free_parking: ParkingIcon,
  freezer: SnowflakeIcon,
  fridge: FridgeIcon,
  game_console: PadIcon,
  garden_or_backyard: TreesIcon,
  grab_rails_in_shower: DropletsIcon,
  grab_rails_in_toilet: HandGrabIcon,
  gym: GymIcon,
  hair_dryer: SunWindIcon,
  hammock: TreesIcon,
  hangers: HangerIcon,
  heater: HeaterIcon,
  home_step_free_access: DisabledIcon,
  home_wide_doorway: DoorIcon,
  hot_water: DropIcon,
  iron: IronIcon,
  jacuzzi: BubbleIcon,
  kayak: KayakIcon,
  kettle: KettleIcon,
  kitchen: ChefHatIcon,
  lake_access: SailboatIcon,
  laundromat_nearby: WashMachineIcon,
  load_shedding_amenities: LoadSheddingIcon,
  long_term_stays_allowed: HomeIcon,
  luggage_dropoff_allowed: LuggageIcon,
  microwave: MicrowaveIcon,
  mosquito_net: BugOffIcon,
  other: OtherIcon,
  outdoor_kitchen: ChefHatIcon,
  outlet_covers: OutletIcon,
  oven: StoveIcon,
  paid_parking_on_premises: ParkingIcon,
  paid_parking: ParkingIcon,
  path_to_entrance_lit_at_night: BulbIcon,
  patio_or_balcony: FenceIcon,
  piano: PianoIcon,
  ping_pong_table: PingPongIcon,
  pocket_wifi: Wifi2Icon,
  pool_hoist: HandGrabIcon,
  pool_table: SportBillardIcon,
  portable_fan: FanIcon,
  portable_fans: FanIcon,
  private_entrance: DoorEnterIcon,
  rain_shower: DropletsIcon,
  record_player: DiscIcon,
  resort_access: FlipFlopsIcon,
  rice_maker: KitchenToolsIcon,
  rollin_shower: DropletsIcon,
  safe: LockSquareIcon,
  sauna: SteamIcon,
  shampoo: BottleIcon,
  shower_chair: DropletsIcon,
  shower_gel: BottleIcon,
  single_level_home: HomeIcon,
  ski_in_ski_out: SnowflakeIcon,
  smoke_alarm: BellIcon,
  sound_system: SoundSystemIcon,
  step_free_access: DisabledIcon,
  stove: StoveIcon,
  street_parking: ParkingIcon,
  swimming_pool: PoolIcon,
  toaster: BreadIcon,
  tv: TvIcon,
  wardrobe_or_closet: ShirtIcon,
  washing_machine: SockIcon,
  waterfront: RippleIcon,
  wide_doorway: DoorIcon
};

const categories = {
  bathroom: {
    name: "Bathroom"
  },
  bedroom_and_laundry: {
    name: "Bedroom and laundry"
  },
  entertainment: {
    name: "Entertainment and office"
  },
  family: {
    name: "Family"
  },
  parking_and_facilities: {
    name: "Parking and facilities"
  },
  heating_and_cooling: {
    name: "Heating and cooling"
  },
  internet_and_office: {
    name: "Internet and office"
  },
  kitchen_and_dining: {
    name: "Kitchen and dining"
  },
  location: {
    name: "Location"
  },
  none: {
    name: "Other"
  },
  safety: {
    name: "Safety"
  },
  services: {
    name: "Services"
  }
};

export const AmenitiesList = ({ propertyId }) => {
  const [amenities, setAmenities] = useState(null);

  const getAmenities = useCallback(() => {
    api
      .get(`/v1/guests/home_info/${propertyId}/amenities`)
      .then(res => {
        setAmenities(res);
      });
  }, [setAmenities, propertyId]);

  useEffect(() => {
    if (!amenities) {
      getAmenities();
    }
  }, [amenities, getAmenities]);

  if (amenities) {
    return (
      <>
        {Object.entries(amenities).map(category => {
          return (
            <li
              className="PropertyInformation__item"
              key={category[0]}
            >
              <span className="PropertyInformation__heading small-header">
                {categories[category[0]]?.name}
              </span>
              <ul>
                {category[1].map(amenity => {
                  const IconComponent =
                    amenityIcon[amenity.key];

                  return (
                    <li
                      key={amenity.key}
                      className="PropertyInformation__amenity"
                    >
                      <span className="PropertyInformation__amenity-text">
                        {IconComponent && (
                          <IconComponent className="PropertyInformation__amenity-icon" />
                        )}
                        {amenity.name}
                      </span>
                      <span className="PropertyInformation__text">
                        {amenity.external_notes?.trim()}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </>
    );
  }

  return null;
};
