import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import {
  ACCESS_TYPE_KEYLESS_DIGITAL_LOCK,
  ACCESS_TYPE_RAILING_LOCKBOX,
  ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
} from "../../../../../data/accessTypes";

const LockboxInstructions = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  const location = property?.home_info?.location;
  const accessType =
    property?.home_info?.location?.access_type;
  const code =
    accessType === ACCESS_TYPE_KEYLESS_DIGITAL_LOCK
      ? location?.digital_lock_code
      : location?.lockbox_code;

  return (
    <>
      <p>
        Access to the home can be obtained from a{" "}
        {location?.access_type}
      </p>

      {location?.access_map && (
        <p>
          <strong>
            Please find a detailed guide with photos for
            accessing the property{" "}
            <a
              href={location.access_map}
              target="_blank"
              rel="noreferrer"
            >
              here.
            </a>
          </strong>
        </p>
      )}

      {code ? (
        <p>
          The code to the {location?.access_type} is{" "}
          <strong>{code}</strong>.{" "}
          {accessType === ACCESS_TYPE_RAILING_LOCKBOX ||
          accessType === ACCESS_TYPE_WALL_MOUNTED_LOCKBOX
            ? "Please do not leave any codes visible after you collect your keys and please ensure to return the keys to the lockbox at checkout."
            : "Do not leave any codes visible."}
        </p>
      ) : (
        <p>
          You will receive your lockbox code 48 hours before
          your arrival date.
        </p>
      )}

      <p>{location?.access_type_notes}</p>
    </>
  );
};

export default LockboxInstructions;
