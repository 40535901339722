import React from "react";

const EnjoyYourStayKeysReminder = () => (
  <li className="PropertyAccess__item big-text">
    Enjoy your stay!
    <div className="PropertyAccess__description">
      <p>
        Please be sure to bring keys with you each time you
        leave the property and take care not to lose any
        keys during your stay. A callout fee will be charged
        to provide access to the property during your stay
        or if keys are not returned at checkout to the place
        you picked them up. Thank you in advance for your
        cooperation and understanding!
      </p>
    </div>
  </li>
);

export default EnjoyYourStayKeysReminder;
