import { useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { debounce } from "lodash";
import * as Sentry from "@sentry/react";
import { Input } from "../Input";
import "./ReviewTipCustomAmountForm.scss";
import { useReviewContext } from "../../data/ReviewContext";

// valid: X, X.X, X.XX, X.000, X.0000, X.XX00, X.X0000 etc
const twoDigits = /^\d+(\.\d{0,2})?$/;

export const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .max(100)
    .min(1)
    .test(
      "is-decimal",
      "Please enter a valid amount",
      value => (value ? twoDigits.test(value) : true)
    )
    .required()
});

const AmountInput = () => {
  const { errors, values, submitForm } = useFormikContext();
  const { setFormError } = useReviewContext();

  useEffect(() => {
    const debouncedSubmit = debounce(submitForm, 500);

    if (!errors.amount) {
      setFormError(false);
      debouncedSubmit();
    } else {
      setFormError(true);
    }
  }, [errors.amount, submitForm, values]);

  return (
    <Input
      type="number"
      name="amount"
      label="Amount:"
      step="0.01"
    />
  );
};

export const ReviewTipCustomAmountForm = ({
  currencySymbol
}) => {
  const { tipAmount, setTipAmount } = useReviewContext();
  const onSubmit = ({ amount }) => {
    Sentry.captureMessage(
      `Tips: entered custom tip amount: ${amount}`
    );
    setTipAmount(amount);
  };

  return (
    <Formik
      initialValues={{ amount: tipAmount }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form className="TipForm">
        <div className="TipForm__wrapper">
          <span className="TipForm__currency">
            {currencySymbol}
          </span>
          <AmountInput />
        </div>
      </Form>
    </Formik>
  );
};
