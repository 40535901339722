// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-UserConsent-consent{border-radius:var(--ods-border-radius-medium);border:1px solid rgba(var(--ods-color-border-separator));background:rgba(var(--ods-color-background-main));text-align:left}.onfido-sdk-ui-UserConsent-header{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start;gap:6px}.onfido-sdk-ui-UserConsent-title{-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1}.onfido-sdk-ui-UserConsent-header>button{background:rgba(var(--ods-color-neutral-300));border:0;min-width:48px;min-height:48px}.onfido-sdk-ui-UserConsent-content{margin:8px 48px 16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consent": "onfido-sdk-ui-UserConsent-consent",
	"header": "onfido-sdk-ui-UserConsent-header",
	"title": "onfido-sdk-ui-UserConsent-title",
	"content": "onfido-sdk-ui-UserConsent-content"
};
export default ___CSS_LOADER_EXPORT___;
