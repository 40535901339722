import React, {
  useEffect,
  useCallback,
  useState
} from "react";
import { init } from "onfido-sdk-ui";
import { useNavigate } from "react-router-dom";

import api from "services/api";
import loader from "assets/loader.webp";
import { useAppContext } from "AppContext";

export const OnfidoWidget = () => {
  const [loading, setLoading] = useState(true);
  const [onfidoToken, setOnfidoToken] = useState(null);
  const { getBooking } = useAppContext();
  const navigate = useNavigate();

  const getOnfidoToken = useCallback(() => {
    api
      .get("/v1/guests/onfido/token")
      .then(res => {
        setOnfidoToken(res.token);
      })
      .catch(err => {
        navigate("/error", {
          state: { errorMessage: err.message }
        });
      });
  });

  const initializeOnfido = useCallback(() => {
    window.onfidoOut = init({
      containerId: "onfido-mount",
      useModal: false,
      token: onfidoToken,
      onComplete: data => {
        api
          .post("/v1/guests/onfido/submit", null, {
            variant: data.face.variant
          })
          .then(() => {
            getBooking();
          })
          .catch(err => {
            navigate("/error", {
              state: { errorMessage: err.message }
            });
          });
      },
      steps: [
        {
          type: "welcome",
          options: {
            title: "Verify your identity",
            descriptions: [
              "We need to double check your details before we send you full data to access the property.",
              "It will only take a minute."
            ],
            nextButton: "Verify Identity"
          }
        },
        {
          type: "document"
        },
        {
          type: "face",
          options: {
            // Default to video check if supported
            requestedVariant: "video"
          }
        },
        {
          type: "complete",
          options: {
            message: "Please wait",
            submessage:
              "We are uploading your documents now..."
          }
        }
      ]
    });

    const welcomeTextElement =
      document.getElementsByClassName(
        "onfido-sdk-ui-Welcome-text"
      )[0];

    if (welcomeTextElement) {
      welcomeTextElement.innerHTML = `<div class="verification-examples">
        <div>
          <img src="/static/verification/verification-examples-docs.svg" alt="ID photo instruction"/>
          <p>Only Photo IDs are accepted, please ensure there is an image to identify you with.</p>
        </div>

        <div>
          <img src="/static/verification/verification-examples-face.svg" alt="Face photo instruction"/>
          <p>Taking a photo using your device is preferred, as uploaded images often get rejected.</p>
        </div>
      </div>`;
    }

    setLoading(false);
  }, [onfidoToken]);

  useEffect(() => {
    getOnfidoToken();
  }, []);

  useEffect(() => {
    if (onfidoToken) {
      initializeOnfido();
    }

    return () => {
      if (window.onfidoOut) {
        window.onfidoOut.tearDown();
      }
    };
  }, [onfidoToken]);

  return (
    <div id="onfido-mount">
      {loading && (
        <img src={loader} alt="Loader" className="loader" />
      )}
    </div>
  );
};
