import {
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import api from "services/api";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({
  children,
  mode = "default"
}) => {
  const { bookingToken } = useParams();

  const navigate = useNavigate();
  const [booking, setBooking] = useState({});
  const [bookingLoading, setBookingLoading] =
    useState(true);
  const [property, setProperty] = useState();

  Sentry.configureScope(scope => {
    scope.setTag("booking_token", bookingToken);
  });

  const getBooking = useCallback(() => {
    api
      .get("/v1/guests/booking")
      .then(res => {
        setBookingLoading(false);
        setBooking(res);
      })
      .catch(err => {
        navigate("/error", {
          state: { errorMessage: err.message }
        });
      });
  }, [bookingToken]);

  const updateBooking = useCallback(values => {
    api
      .patch("/v1/guests/booking", null, values)
      .then(res => {
        setBooking(res);
      })
      .catch(err => {
        navigate("/error", {
          state: { errorMessage: err.message }
        });
      });
  });

  const getPropertyInfo = useCallback(() => {
    api
      .get(`/v1/guests/property/${bookingToken}`)
      .then(res => {
        setProperty(res);
      })
      .catch(err => {
        navigate("/error", {
          state: {
            errorMessage: err.message,
            context: "preview"
          }
        });
      });
  }, [bookingToken]);

  useEffect(() => {
    api.setToken(bookingToken);

    if (mode === "default") {
      getBooking();
    }

    // for preview mode in Airbase
    if (mode === "preview") {
      getPropertyInfo();
    }
  }, [getBooking, getPropertyInfo, mode]);

  const contextValue = useMemo(() => {
    if (mode === "preview") {
      return {
        booking: {
          property,
          property_access_instructions_available: true
        }
      };
    }
    return {
      booking,
      updateBooking,
      bookingLoading,
      getBooking
    };
  }, [booking, mode, property]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
