import { useAppContext } from "AppContext";

export const ContactInfo = () => {
  const {
    booking: { contact_number: contactNumber }
  } = useAppContext();

  if (contactNumber) {
    return (
      <div>
        You should find all the information you need on this
        page. If you cannot find what you need in these
        instructions or in case of an emergency you may
        contact us on this number:{" "}
        <a href={`tel:${contactNumber}`}>{contactNumber}</a>
      </div>
    );
  }

  return null;
};
