import { ErrorMessage, useFormikContext } from "formik";
import { useReviewContext } from "data/ReviewContext";
import { Option } from "components/Review/Fields";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";

const options = [
  { text: "Bathroom", id: "bathroom" },
  { text: "Bedroom", id: "bedroom" },
  { text: "Lounge", id: "lounge" },
  { text: "Kitchen", id: "kitchen" },
  {
    text: "Kitchen utensils",
    id: "kitchen-utensils"
  },
  { text: "Floors", id: "floors" },
  { text: "Surfaces", id: "surfaces" }
];

export const CleanIssues = () => {
  const { handleChange, values } = useFormikContext();
  const { handleSetValues } = useReviewContext();
  const FIELD_NAME = "cleanIssues";

  const onChange = event => {
    const {
      target: { value }
    } = event;

    let newValues;

    if (values.cleanIssues.includes(value)) {
      newValues = values.cleanIssues.filter(
        el => el !== value
      );
    } else {
      newValues = [...values.cleanIssues, value];
    }

    handleChange(event);
    handleSetValues(newValues, FIELD_NAME);
  };

  return (
    <div className="ReviewForm__section">
      <p className="ReviewForm__options-text">
        Which areas had the biggest issues?
      </p>

      {options.map(({ id, text }) => (
        <Option
          key={id}
          id={id}
          name={FIELD_NAME}
          onChange={onChange}
          text={text}
        />
      ))}

      <ErrorMessage
        name={FIELD_NAME}
        component={ErrorMessageContainer}
      />
    </div>
  );
};
