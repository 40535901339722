import { useContext } from "react";
import { AppContext } from "../../../../AppContext";

const DigitalLock = () => {
  const {
    booking: { property }
  } = useContext(AppContext);

  return (
    <li className="PropertyAccess__item big-text">
      At the apartment door, enter code{" "}
      {property?.home_info?.location.digital_lock_code} to
      open.
    </li>
  );
};

export default DigitalLock;
