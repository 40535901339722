import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { KeystoreInformation } from "../../components/KeystoreInformation";

const LOCATION_TYPE = {
  LOCKER: "locker",
  STORE: "store"
};

const StoreInstructions = ({ guest_key: guestKey }) => {
  return (
    <p>
      Access to the home can be obtained from a keystore{" "}
      {guestKey?.keystore_location?.name} located at{" "}
      {guestKey?.keystore_location?.street_address}. Go to
      the counter at the keynest location, mention you are
      picking up keys, and provide the code{" "}
      {guestKey?.collection_code ? (
        <> - {guestKey?.collection_code}.</>
      ) : (
        "."
      )}
    </p>
  );
};

const LockerInstructions = ({ guest_key: guestKey }) => {
  return (
    <p>
      Keys to the home can be obtained from a key locker{" "}
      {guestKey?.keystore_location?.name} located at{" "}
      {guestKey?.keystore_location?.street_address}.
      <br></br>
      <a href={guestKey?.access_link}>
        Check locker access instructions here
      </a>
    </p>
  );
};

const Keynest = () => {
  const {
    booking: {
      contact_number: contactNumber,
      guest_key: guestKey,
      property
    }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      {guestKey?.keystore_location.location_type ===
      LOCATION_TYPE.LOCKER ? (
        <LockerInstructions guest_key={guestKey} />
      ) : (
        <StoreInstructions guest_key={guestKey} />
      )}

      {!guestKey?.collection_code && (
        <p>
          You will receive your keynest code 48 hours before
          your arrival date.
        </p>
      )}

      <p>{location?.access_type_notes}</p>

      <KeystoreInformation
        contactNumber={contactNumber}
        guestKey={guestKey}
        location={location}
      />
    </>
  );
};

export default Keynest;
