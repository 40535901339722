import "./DescriptionItem.scss";

export const DescriptionItem = ({ title, content }) => {
  return (
    <dl className="list">
      <dt className="label-info">{title}</dt>
      <dd className="list__description">{content}</dd>
    </dl>
  );
};
