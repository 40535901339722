import axios from "axios";
import * as Sentry from "@sentry/react";
import config from "../config";

export const NETWORK_ERROR_MESSAGE = "Network Error";
export const TIMEOUT_ERROR_CODE = "ECONNABORTED";

export const ERROR_NOT_FOUND = "NOT_FOUND";
export const DEFAULT_ERROR_MESSAGE = "UNKNOWN_SERVER_ERROR";
export const ERROR_NO_ACCESS = "NO_ACCESS";
export const ERROR_BOOKING_EXPIRED = "BOOKING_EXPIRED";
export const ERROR_NETWORK = "NETWORK_ERROR";
export const ERROR_HOST_NOT_SET = "HOST_NOT_SET_ERROR";

export const sanitizeErrorResponse = ({ code }) => {
  switch (code) {
    case 404:
    case 401:
      return ERROR_NOT_FOUND;
    case 403:
      return ERROR_NO_ACCESS;
    case 410:
      return ERROR_BOOKING_EXPIRED;
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

const errorMessage = error => {
  if (
    (error.message === NETWORK_ERROR_MESSAGE &&
      error.code === undefined) ||
    error.code === TIMEOUT_ERROR_CODE
  ) {
    return ERROR_NETWORK;
  }

  return error.response
    ? sanitizeErrorResponse(error.response.data ?? {})
    : DEFAULT_ERROR_MESSAGE;
};

export class ApiManager {
  constructor({ host = null, token = null } = {}) {
    this.setHost(host);
    this.setToken(token);
  }

  setToken(token) {
    this.token = token;
  }

  setHost(host) {
    this.host = host;
  }

  request(method, route, params, body = {}) {
    if (!this.host && !route.startsWith("http")) {
      throw new Error(ERROR_HOST_NOT_SET);
    }

    const headers = {};
    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }

    if (this.token) {
      headers["airsorted-auth-token"] = this.token;
    }

    const url = this.host ? `${this.host}${route}` : route;

    return axios({
      url,
      method,
      params,
      headers,
      data: body
    }).then(
      response => response.data,
      error => {
        Sentry.captureException(error);
        return Promise.reject(
          new Error(errorMessage(error))
        );
      }
    );
  }

  get = (...args) => this.request("GET", ...args);

  post = (...args) => this.request("POST", ...args);

  put = (...args) => this.request("PUT", ...args);

  patch = (...args) => this.request("PATCH", ...args);

  delete = (...args) => this.request("DELETE", ...args);
}

const api = new ApiManager({ host: config.API_HOST });

export default api;
