import "./Header.scss";

import { ReactComponent as Logo } from "assets/logo.svg";
import { GoogleTranslate } from "components/GoogleTranslate";

export const Header = () => {
  return (
    <header className="Header">
      <Logo />
      <GoogleTranslate />
    </header>
  );
};
