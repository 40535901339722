import range from "lodash/range";
// import format from "date-fns/format";

const MIDNIGHT_CHECKIN_TIME = "23:59:00";
export const DEFAULT_EARLIEST_CHECKIN_TIME = "15:00";
export const DEFAULT_LATEST_CHECKOUT_TIME = "11:00";

export const formatTimeString = timeString => {
  // expecting time format to be HH:MM:SS
  const splits = timeString.split(":");

  return `${splits[0]}:${splits[1]}`;
};

export const getCheckinTime = booking => {
  const {
    property: {
      earliest_checkin_time: earliestCheckinTime
    },
    checkin_time: checkinTime
  } = booking;

  if (checkinTime) {
    // If the guest chose to checkin at midnight the value we send to the server is 23:59:00
    // to make sure the checkinTime remains on the same day as the booking and not the next day. To
    // see this value in the info section may be confusing so we format back to a more readable 'midnight'
    if (checkinTime === MIDNIGHT_CHECKIN_TIME) {
      return "midnight";
    }

    return formatTimeString(checkinTime);
  }

  if (earliestCheckinTime) {
    return formatTimeString(earliestCheckinTime);
  }

  return DEFAULT_EARLIEST_CHECKIN_TIME;
};

export const getCheckOutTime = booking => {
  const {
    property: { latest_checkout_time: latestCheckoutTime },
    checkout_time: checkoutTime
  } = booking;

  if (checkoutTime) {
    return formatTimeString(checkoutTime);
  }

  if (latestCheckoutTime) {
    return formatTimeString(latestCheckoutTime);
  }

  return DEFAULT_LATEST_CHECKOUT_TIME;
};

export const generateCheckInTimesOptions =
  earliestCheckinTime => {
    const splits = earliestCheckinTime.split(":");
    const hour = parseInt(splits[0], 10);

    return range(hour, 25, 1)
      .map(hourValue => {
        if (hourValue === 24) {
          return {
            text: "00:00 or later",
            value: MIDNIGHT_CHECKIN_TIME
          };
        }

        let value = `${hourValue}:00`;

        if (hourValue < 10) {
          value = `0${hourValue}:00`;
        }

        return {
          text: value,
          value
        };
      })
      .reverse();
  };

export const generateCheckOutTimesOptions =
  latestCheckoutTime => {
    const splits = latestCheckoutTime.split(":");
    const hour = parseInt(splits[0], 10);

    return range(6, hour + 1, 1).map(hourValue => {
      const value =
        hourValue < 10
          ? `0${hourValue}:00`
          : `${hourValue}:00`;

      return {
        text: value,
        value
      };
    });
  };
