import { Confetti } from "components/Confetti";
import { useAppContext } from "AppContext";
import { ChekinInfo } from "components/ChekinInfo";

export const Done = () => {
  const { booking } = useAppContext();

  return (
    <div className="DoneStep">
      {booking && booking.chekin_url ? (
        <ChekinInfo />
      ) : (
        <>
          <Confetti />
          <p className="small-header">
            Thanks! You are now checked in!
          </p>
          <p>
            We are double checking your details now. We will
            be in touch only if there are any issues. You
            will receive check-in instrucions, via email, 48
            hours before your arrival date.
          </p>
        </>
      )}
    </div>
  );
};
