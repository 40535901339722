import { Accordion } from "components/Accordion";
import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import { ReactComponent as PlugIcon } from "assets/icons/plug.svg";
import { DescriptionItem } from "../../DescriptionItem";

export const Parking = ({ property }) => {
  const parkingNotes = property?.home_info?.parking_notes;
  const parkingType = property?.home_info?.parking_type;
  const EVChargerNotes =
    property?.home_info?.ev_charger_notes;

  if (!parkingNotes && !parkingType && !EVChargerNotes) {
    return null;
  }

  return (
    <Accordion title="Parking">
      <ul className="PropertyInformation__list">
        <li className="PropertyInformation__item">
          <span className="PropertyInformation__heading small-header">
            <CarIcon className="PropertyInformation__icon" />
            Parking Information
          </span>
          {parkingType && (
            <DescriptionItem
              title="Parking type"
              content={parkingType}
            />
          )}
          {parkingNotes && (
            <DescriptionItem
              title="Parking notes"
              content={parkingNotes}
            />
          )}
        </li>
        {EVChargerNotes && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <PlugIcon className="PropertyInformation__icon" />
              EV charger notes
            </span>

            <span className="PropertyInformation__text">
              {EVChargerNotes}
            </span>
          </li>
        )}
      </ul>
    </Accordion>
  );
};
