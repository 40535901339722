import { useFormikContext } from "formik";
import { useReviewContext } from "data/ReviewContext";
import { Option } from "components/Review/Fields";

const options = [
  {
    text: "Sparkly clean",
    id: "sparkly-clean"
  },
  {
    text: "Perfect presentation",
    id: "perfect-presentation"
  },
  {
    text: "Beautiful beds",
    id: "beautiful-beds"
  },
  {
    text: "Exceeded expectations",
    id: "exceeded-expectations"
  },
  {
    text: "Felt like home",
    id: "felt-like-home"
  },
  {
    text: "Attention to detail",
    id: "attention-to-detail"
  }
];

export const CleanerCompliments = () => {
  const { handleSetValues } = useReviewContext();
  const { handleChange, values } = useFormikContext();
  const FIELD_NAME = "cleanerCompliments";

  const onChange = event => {
    const {
      target: { value }
    } = event;
    let newValues;
    if (values.cleanerCompliments.includes(value)) {
      newValues = values.cleanerCompliments.filter(
        el => el !== value
      );
    } else {
      newValues = [...values.cleanerCompliments, value];
    }
    handleChange(event);
    handleSetValues(newValues, FIELD_NAME);
  };

  return (
    <div className="ReviewForm__section">
      <p className="ReviewForm__options-text">
        Any compliments to the staff? We&apos;ll let them
        know!
      </p>

      {options.map(({ id, text }) => (
        <Option
          key={id}
          id={id}
          name={FIELD_NAME}
          onChange={onChange}
          text={text}
        />
      ))}
    </div>
  );
};
