import { h, FunctionComponent } from 'preact'

export const PersonIcon: FunctionComponent = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2.666c-7.352 0-13.334 5.981-13.334 13.333S8.648 29.333 16 29.333c7.352 0 13.333-5.982 13.333-13.334 0-7.352-5.981-13.333-13.333-13.333Zm0 2.667c4.284 0 7.976 2.544 9.672 6.197-.412-.195-.81-.417-1.194-.664-.545-.344-1.164-.735-1.946-1.03-.892-.34-1.867-.503-2.979-.503s-2.087.164-2.979.502c-.782.298-1.4.688-1.962 1.042-.504.316-.938.59-1.47.792-.595.225-1.24.33-2.035.33-.794 0-1.44-.105-2.032-.33-.534-.202-.971-.478-1.475-.798a25.866 25.866 0 0 0-.693-.417C8.783 7.389 12.15 5.333 16 5.333Zm0 21.333c-5.882 0-10.667-4.785-10.667-10.667 0-1.08.164-2.12.464-3.102.126.077.247.146.378.23.548.347 1.168.739 1.954 1.035.892.34 1.866.504 2.978.504 1.11 0 2.085-.164 2.98-.503.782-.297 1.4-.688 1.962-1.041.504-.316.938-.59 1.47-.793.594-.224 1.24-.33 2.034-.33.795 0 1.44.106 2.034.331.532.2.966.475 1.485.803.545.344 1.164.734 1.947 1.03a7.5 7.5 0 0 0 1.537.388c.065.476.11.956.11 1.448 0 5.882-4.785 10.667-10.666 10.667Z"
        fill="#2B2D33"
      />
      <path
        d="M11.334 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM20.666 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill="#2B2D33"
      />
    </svg>
  )
}
