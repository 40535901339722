import "./DetailsHeader.scss";

import { useAppContext } from "AppContext";

export const DetailsHeader = () => {
  const { booking } = useAppContext();

  if (booking && booking.property) {
    const { property, terms_accepted } = booking;
    const {
      city,
      full_address: fullAddress,
      home_info: homeInfo,
      locality: { name: localityName },
      building_name: buildingName
    } = property;

    const floorInfo = homeInfo?.unit_floor
      ? `${homeInfo?.unit_floor} floor`
      : null;

    const addressInfo = [
      buildingName,
      fullAddress,
      floorInfo
    ]
      .filter(value => !!value)
      .join(", ");

    return (
      <section className={`DetailsHeader City ${city}`}>
        <h1 className="main-header">
          {terms_accepted
            ? `Your stay at ${addressInfo}`
            : `Your stay in ${localityName}`}
        </h1>
      </section>
    );
  }

  return null;
};
