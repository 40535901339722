import * as yup from "yup";

export const reviewSchema = yup.object().shape({
  cleanIssues: yup.array().when("cleanRating", {
    is: val => {
      return +val < 5;
    },
    then: () =>
      yup
        .array()
        .min(
          1,
          "Please let us know which areas had the biggest issues."
        )
  }),
  cleanNotes: yup.string().when("cleanRating", {
    is: val => +val < 5,
    then: () =>
      yup
        .string()
        .required(
          "Please let us know how we can improve our service."
        )
  }),
  propertyNotes: yup.string().when("propertyRating", {
    is: val => val < 5,
    then: () =>
      yup
        .string()
        .required("Please let us know of any issues.")
  })
});
