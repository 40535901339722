import { Button } from "components/Button";
import { useEffect, useState } from "react";
import api from "services/api";
import classNames from "classnames";
import { useAppContext } from "../../AppContext";

const UNLOCK_URL = "/v1/guests/smart_lock/unlock";
const GET_ATTEMPT_URL = "/v1/guests/smart_lock/attempt/";
const GET_ATTEMPT_INTERVAL = 2000;
const GET_ATTEMPT_RETRIES = 3;
const UNLOCK_BUTTON_STATE_TIMEOUT = 10000;
const BUTTON_TEXTS = {
  null: "Unlock building door",
  success: "Door unlocked!",
  failed: "Failed to unlock",
  default: "Unlocking..."
};

const getButtonText = unlockState =>
  BUTTON_TEXTS[unlockState] || BUTTON_TEXTS.default;

const sleep = ms =>
  new Promise(resolve => setTimeout(resolve, ms));

const getAttempt = action_attempt_id => {
  return api.get(GET_ATTEMPT_URL + action_attempt_id);
};

const isUnlockingFinished = status =>
  ["success", "failed"].includes(status);

const pollAttempt = async (action_attempt_id, status) => {
  if (isUnlockingFinished(status)) {
    return status;
  }
  for (let i = 0; i < GET_ATTEMPT_RETRIES; i++) {
    await sleep(GET_ATTEMPT_INTERVAL);
    let resp = await getAttempt(action_attempt_id);
    status = resp["status"];
    if (isUnlockingFinished(status)) {
      return status;
    }
  }
  return "failed";
};

const getClassName = unlockState => {
  return classNames({
    "Button--success": unlockState === "success",
    "Button--failure": unlockState === "failed"
  });
};

export const UnlockButton = () => {
  const { booking } = useAppContext();
  const [unlockState, setUnlockState] = useState(null);
  const [isUnlocking, setIsUnlocking] = useState(false);

  useEffect(() => {
    /**
     * Set a timeout to reset the unlockState to null after a specified duration
     * when button is not currently unlocking. If component state changes before
     * the timeout completes, the timeout is cleared to prevent
     * unexpected state changes.
     *
     */
    if (!isUnlocking) {
      const timeoutId = setTimeout(() => {
        setUnlockState(null);
      }, UNLOCK_BUTTON_STATE_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }
  }, [isUnlocking]);

  const unlock = async () => {
    try {
      setIsUnlocking(true);
      const resp = await api.post(UNLOCK_URL);
      let { action_attempt_id, status } = resp;
      setUnlockState(status);
      status = await pollAttempt(action_attempt_id, status);
      setUnlockState(status);
    } catch (error) {
      setUnlockState("failed");
    } finally {
      setIsUnlocking(false);
    }
  };
  return (
    <>
      <Button
        onClick={unlock}
        disabled={unlockState !== null || isUnlocking}
        ownClassName={getClassName(unlockState)}
      >
        {getButtonText(unlockState)}
      </Button>
      {unlockState === "failed" && (
        <>
          {booking?.contact_number && (
            <div>
              Please contact our guest support team on{" "}
              <a href={`tel:${booking.contact_number}`}>
                {booking.contact_number}
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
};
