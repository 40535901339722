import { useAppContext } from "AppContext";
import { DetailsForm } from "components/DetailsForm";
import loader from "assets/loader.webp";
import { DetailsHeader } from "components/DetailsHeader";
import { CheckinCheckout } from "components/CheckinCheckout";

export const StayDetailsFormPage = () => {
  const { booking, bookingLoading } = useAppContext();

  if (bookingLoading) {
    return (
      <img src={loader} alt="Loader" className="loader" />
    );
  }

  if (booking && booking.property) {
    return (
      <div>
        <DetailsHeader />

        <CheckinCheckout />

        <p className="yellow-header">
          Please fill in these details, this will let us
          provide you with better experience. After filling
          this form you’ll see property access instructions.
        </p>

        <DetailsForm />
      </div>
    );
  }

  return null;
};
