import "./Error.scss";

import { useLocation } from "react-router-dom";
import { useMemo } from "react";

import {
  ERROR_NOT_FOUND,
  ERROR_NO_ACCESS,
  ERROR_BOOKING_EXPIRED
} from "services/api";
import confetti from "assets/error/confetti.webp";
import error from "assets/error/error.webp";
import access from "assets/error/access.webp";
import confettiStatic from "assets/error/confetti-static.png";
import errorStatic from "assets/error/error-static.png";
import accessStatic from "assets/error/access-static.png";

const ICONS = {
  [ERROR_BOOKING_EXPIRED]: confetti,
  [ERROR_NO_ACCESS]: access
};

const IMG_STATIC = {
  [ERROR_BOOKING_EXPIRED]: confettiStatic,
  [ERROR_NO_ACCESS]: accessStatic
};

export const Error = () => {
  const location = useLocation();
  const message = location?.state?.errorMessage;
  const errorContext = location?.state?.context;
  const errorImage = document.querySelector("#error-img");

  const errorMessage = useMemo(() => {
    switch (message) {
      case ERROR_NOT_FOUND:
        return "Sorry we couldn’t find that page! This page is missing or you assembled the link incorrectly.";
      case ERROR_NO_ACCESS:
        return "Sorry, you don’t have access to this page.";
      case ERROR_BOOKING_EXPIRED:
        if (errorContext === "preview") {
          return "This link has expired. To see home info preview go to the Airbase and click 'Open preview in guest app' button";
        }
        return "This link has expired. Thank you for visiting and we hope you enjoyed your stay.";
      default:
        return "Something went wrong. We are working on fixing the problem. Please try again.";
    }
  }, [message]);

  function stopImage() {
    setTimeout(() => {
      if (errorImage) {
        errorImage.src = IMG_STATIC[message] || errorStatic;
      }
    }, 2450);
  }

  return (
    <div className="Error">
      <img
        src={ICONS[message] || error}
        alt="Error"
        id="error-img"
        onLoad={stopImage}
      />

      <p className="header">{errorMessage}</p>
    </div>
  );
};
