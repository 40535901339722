import "./ReviewForm.scss";

import { Form, Formik } from "formik";
import { useMemo } from "react";
import {
  CleanerCompliments,
  CleanIssues,
  CleanNotes,
  CleanRating,
  PositiveSuggestions,
  PropertyNotes,
  PropertyRating
} from "components/Review/Sections";
import { useReviewContext } from "data/ReviewContext";
import { reviewSchema } from "data/reviewValidationSchema";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";
import { Button } from "components/Button";

export const ReviewForm = () => {
  const {
    cleanerCompliments,
    cleanIssues,
    cleanNotes,
    cleanRating,
    propertyRating,
    propertyNotes,
    positiveSuggestions,
    handleFinish,
    hasErrors,
    isSubmitting
  } = useReviewContext();

  const initialValues = useMemo(
    () => ({
      cleanerCompliments,
      cleanIssues,
      cleanNotes,
      cleanRating: cleanRating
        ? cleanRating.toString()
        : "",
      propertyNotes,
      propertyRating: propertyRating
        ? propertyRating.toString()
        : "",
      positiveSuggestions
    }),
    [
      cleanIssues,
      cleanNotes,
      cleanRating,
      cleanerCompliments,
      positiveSuggestions,
      propertyNotes,
      propertyRating
    ]
  );

  const onSubmit = () => {
    handleFinish();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={reviewSchema}
    >
      {({ values }) => (
        <Form className="ReviewForm">
          <CleanRating />

          {+values.cleanRating === 5 && (
            <CleanerCompliments />
          )}

          {+values.cleanRating <= 4 && (
            <>
              <CleanIssues />
              <CleanNotes />
            </>
          )}

          <PropertyRating />

          {+values.propertyRating > 0 &&
            +values.propertyRating < 5 && <PropertyNotes />}

          {+values.propertyRating === 5 &&
            +values.cleanRating === 5 && (
              <PositiveSuggestions />
            )}

          <div className="ReviewForm__submit-wrapper">
            {hasErrors && (
              <ErrorMessageContainer>
                An error occurred, please try again later.
              </ErrorMessageContainer>
            )}
            <Button
              type="submit"
              ownClassName="ReviewForm__submit"
              disabled={isSubmitting}
              testId="next-button"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
