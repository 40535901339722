import "./DetailsForm.scss";

import { useMemo } from "react";
import { Formik, Form, Field } from "formik";

import { Input } from "components/Input/Input";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Button } from "components/Button";
import {
  generateCheckInTimesOptions,
  generateCheckOutTimesOptions
} from "utils/dateTime";
import { useAppContext } from "AppContext";
import { InfoContainer } from "components/InfoContainer";
import { HouseRules } from "components/HouseRules";
import { KeystoreInformation } from "components/StayDetails/Access/components/KeystoreInformation";
import {
  TRAVEL_REASON_OPTIONS,
  checkinInfoText,
  checkoutInfoText,
  getExtraBedsString,
  initialValues,
  validationSchema
} from "./form_helpers";

export const DetailsForm = () => {
  const { booking, updateBooking } = useAppContext();

  if (booking) {
    const {
      contact_number: contactNumber,
      guest_key: guestKey,
      property: {
        earliest_checkin_time: earliestCheckinTime,
        latest_checkout_time: latestCheckoutTime,
        non_primary_beds: nonPrimaryBeds,
        accommodates,
        home_info: { location }
      },
      guest_can_make_extra_bed_request:
        guestCanMakeExtraBedRequest,
      extra_beds_required: extraBedsRequired
    } = booking;

    const extraBedString = useMemo(
      () => getExtraBedsString(nonPrimaryBeds),
      [nonPrimaryBeds]
    );

    const handleSubmit = values => {
      const formValues = values;
      delete formValues.guestCanMakeExtraBedRequest;

      updateBooking({
        ...formValues,
        extra_beds_required:
          formValues.extra_beds_required === "true"
      });
    };

    return (
      <Formik
        initialValues={initialValues(
          guestCanMakeExtraBedRequest,
          extraBedsRequired
        )}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="DetailsForm">
            <div className="DetailsForm__fields">
              <Input
                name="checkin_time"
                component="select"
                type="text"
                label="Planned check-in time"
                labelWithInfo
                infoText={checkinInfoText(
                  earliestCheckinTime
                )}
              >
                <option disabled value="">
                  Select check-in time
                </option>
                {generateCheckInTimesOptions(
                  earliestCheckinTime
                ).map(checkinOption => (
                  <option
                    key={checkinOption.value}
                    value={checkinOption.value}
                  >
                    {checkinOption.text}
                  </option>
                ))}
              </Input>

              <Input
                name="checkout_time"
                component="select"
                type="text"
                label="Planned check-out time"
                labelWithInfo
                infoText={checkoutInfoText(
                  latestCheckoutTime
                )}
              >
                <option disabled value="">
                  Select check-out time
                </option>
                {generateCheckOutTimesOptions(
                  booking.property.latest_checkout_time
                ).map(checkinOption => (
                  <option
                    key={checkinOption.value}
                    value={checkinOption.value}
                  >
                    {checkinOption.text}
                  </option>
                ))}
              </Input>

              <Input
                name="travel_reason"
                component="select"
                type="text"
                label="Travel reason"
              >
                <option disabled value="">
                  Select travel reason
                </option>
                {TRAVEL_REASON_OPTIONS.map(reason => (
                  <option
                    key={reason.value}
                    value={reason.value}
                  >
                    {reason.text}
                  </option>
                ))}
              </Input>

              <Input
                name="guest_email_personal"
                component="input"
                type="email"
                label="E-mail"
              />

              {guestCanMakeExtraBedRequest && (
                <>
                  <p className="Input__label">
                    Will you require the use of{" "}
                    {extraBedString}?
                  </p>
                  <div
                    role="group"
                    aria-labelledby="extra-beds"
                  >
                    <label className="Radio">
                      Yes
                      <Field
                        type="radio"
                        name="extra_beds_required"
                        value="true"
                        id="yes"
                      />
                    </label>

                    <label className="Radio">
                      No
                      <Field
                        type="radio"
                        name="extra_beds_required"
                        value="false"
                      />
                    </label>
                  </div>

                  <InfoContainer>
                    Property sleeps maximum of{" "}
                    {accommodates} with extra bed.
                  </InfoContainer>
                </>
              )}
            </div>

            <div className="DetailsForm__infos">
              <KeystoreInformation
                contactNumber={contactNumber}
                guestKey={guestKey}
                location={location}
              />

              <HouseRules />
            </div>

            <div className="DetailsForm__checks">
              <Checkbox
                name="terms_accepted"
                label="I have read and accepted the house rules."
              />

              <Checkbox
                name="marketing_opt_in"
                label="I agree to be contacted about my stay and to receive information on discounts on future stays."
              />

              <Button type="submit">Submit</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return null;
};
