import "./CheckinCheckout.scss";

import { useAppContext } from "AppContext";
import {
  getCheckinTime,
  getCheckOutTime
} from "utils/dateTime";

export const CheckinCheckout = () => {
  const { booking } = useAppContext();

  if (booking && booking.property) {
    const {
      checkin_formatted: checkinDate,
      checkout_formatted: checkoutDate
    } = booking;

    return (
      <section className="CheckinCheckout">
        <dl className="CheckinCheckout__content">
          <dt className="label-info">Check-in</dt>
          <dd className="CheckinCheckout__date">
            {checkinDate}
          </dd>
          <dd className="CheckinCheckout__date">
            from {getCheckinTime(booking)}
          </dd>
        </dl>

        <dl className="CheckinCheckout__content">
          <dt className="label-info">Check-out</dt>
          <dd className="CheckinCheckout__date">
            {checkoutDate}
          </dd>
          <dd className="CheckinCheckout__date">
            until {getCheckOutTime(booking)}
          </dd>
        </dl>
      </section>
    );
  }

  return null;
};
