import React, { useMemo } from "react";

import { Button } from "components/Button";
import { useAppContext } from "AppContext";

const LOCALITY_MADRID = "Madrid";
const LOCALITY_BARCELONA = "Barcelona";
const LOCALITY_LISBON = "Lisbon";
const LOCALITY_DUBAI = "Dubai";
const LOCALITY_PARIS = "Paris";

const CHEKIN_LOCALITIES = [
  LOCALITY_MADRID,
  LOCALITY_BARCELONA,
  LOCALITY_LISBON,
  LOCALITY_DUBAI,
  LOCALITY_PARIS
];

export const ChekinInfo = () => {
  const { booking } = useAppContext();
  const {
    chekin_url: chekinUrl,
    channel,
    property
  } = booking;
  const localityName = property?.locality?.name;

  const mainText = useMemo(() => {
    switch (localityName) {
      case LOCALITY_MADRID:
        return "You are required by Spanish law to submit an ID registration for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";

      case LOCALITY_BARCELONA:
        return "You are required by Spanish law to submit ID and pay a small ‘Tourist Tax’ for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";

      case LOCALITY_LISBON: {
        if (channel === "airbnb") {
          return "You are required by Portuguese law to submit an ID registration for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";
        }
        return "You are required by Portuguese law to submit ID and pay a small ‘Tourist Tax’ for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";
      }

      case LOCALITY_DUBAI: {
        return "You are required by UAE law to submit an ID registration for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";
      }

      case LOCALITY_PARIS: {
        if (channel === "airbnb") {
          return "You are required by French law to submit an ID registration for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";
        }
        return "You are required by French law to submit ID and pay a small ‘Tourist Tax’ for all guests staying with you. Don’t worry it will take you just 3-4 minutes to complete the information through our link.";
      }

      default:
        return null;
    }
  }, [localityName, channel]);

  if (!CHEKIN_LOCALITIES.includes(localityName)) {
    return null;
  }

  return (
    <div>
      <p className="small-header">Almost there.</p>

      <p>We look forward to confirming your reservation!</p>

      <p>{mainText}</p>

      <p>
        To confirm your reservation please follow the link
        below.
      </p>

      <a href={chekinUrl}>
        <Button>Go to registration</Button>
      </a>
    </div>
  );
};
