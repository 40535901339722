import { Accordion } from "components/Accordion";
import { ReactComponent as BusIcon } from "assets/icons/bus.svg";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as ToolsKitchen2Icon } from "assets/icons/tools-kitchen-2.svg";
import { checkIfAllFalse } from "utils/helpers";

const GUIDE_LINK =
  "https://www.getyourguide.com/london-l57/?partner_id=2MQ5PV9&utm_medium=online_publisher&utm_source=herman%40houst.com&placement=content-middle";
const LONDON = "London";

export const TransportAndNeighbourhood = ({ property }) => {
  const location = property?.home_info?.location;
  const localityName = property?.locality?.name;
  const nearestStation = location?.nearest_station;
  const nearestSupermarket = location?.nearest_supermarket;
  const favouriteSpots = location?.favourite_spots;
  const nearestRestaurant = location?.nearest_restaurant;

  if (
    checkIfAllFalse([
      location,
      localityName,
      nearestStation,
      nearestSupermarket,
      nearestRestaurant
    ])
  ) {
    return null;
  }

  return (
    <Accordion title="Transport &amp; neighbourhood">
      <ul className="PropertyInformation__list">
        {nearestStation && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <BusIcon className="PropertyInformation__icon" />
              Nearest station
            </span>

            <span className="PropertyInformation__text">
              {nearestStation}
            </span>
          </li>
        )}

        {nearestSupermarket && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <ShopIcon className="PropertyInformation__icon" />
              Nearest supermarket
            </span>

            <span className="PropertyInformation__text">
              {nearestSupermarket}
            </span>
          </li>
        )}

        {favouriteSpots && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <StarIcon className="PropertyInformation__icon" />
              Favourite spots
            </span>

            <span className="PropertyInformation__text">
              {favouriteSpots}
            </span>
          </li>
        )}

        {nearestRestaurant && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <ToolsKitchen2Icon className="PropertyInformation__icon" />
              Nearest restaurant
            </span>

            <span className="PropertyInformation__text">
              {nearestRestaurant}
            </span>
          </li>
        )}
      </ul>

      {localityName === LONDON && (
        <p>
          Check out{" "}
          <a
            href={GUIDE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            GetYourGuide
          </a>{" "}
          for some excellent tours and activities in London.
        </p>
      )}
    </Accordion>
  );
};
