import * as Yup from "yup";
import { formatTimeString } from "utils/dateTime";

export const TRAVEL_REASON_OPTIONS = [
  {
    text: "Visiting for work.",
    value: "visiting_for_work"
  },
  {
    text: "Visiting friends/family that live here.",
    value: "visiting_friends_family"
  },
  {
    text: "Visiting the city to explore.",
    value: "visiting_the_city_to_explore"
  },
  {
    text: "Visiting to attend a particular event.",
    value: "visiting_to_attend_a_particular_event"
  },
  {
    text: "Not visiting, just need a short-term place to stay.",
    value:
      "not_visiting_just_need_a_short_term_place_to_stay"
  },
  {
    text: "Other",
    value: "other"
  }
];

export const checkinInfoText = earliestCheckinTime =>
  `This helps schedule cleaning and preparation for your arrival. If you don’t know yet what time you will arrive, that is fine, just leave it as ${formatTimeString(
    earliestCheckinTime
  )} and the property will be ready by then.`;

export const checkoutInfoText = latestCheckoutTime =>
  `This helps schedule the cleaning after your departure. If you don’t yet know what time you will leave, that is fine, just leave it as ${formatTimeString(
    latestCheckoutTime
  )} and the cleaner will arrive after that.`;

export const getExtraBedsString = nonPrimaryBeds => {
  if (nonPrimaryBeds && nonPrimaryBeds.length) {
    if (nonPrimaryBeds.length > 1) {
      const uniqueBedTypes = [
        ...new Set(
          nonPrimaryBeds.map(bed => {
            return bed.bed_type_label;
          })
        )
      ];

      return uniqueBedTypes.join(" or ");
    }

    return nonPrimaryBeds[0].bed_type_label;
  }

  return null;
};

export const initialValues = (
  guestCanMakeExtraBedRequest,
  extraBedsRequired
) => ({
  checkin_time: "",
  checkout_time: "",
  travel_reason: "",
  guest_email_personal: undefined,
  extra_beds_required: extraBedsRequired,
  terms_accepted: false,
  marketing_opt_in: false,
  guestCanMakeExtraBedRequest
});

export const validationSchema = Yup.object().shape({
  guest_email_personal: Yup.string()
    .nullable()
    .email("Must be a valid email address"),
  checkin_time: Yup.string().required(
    "Please choose when you plan to check in"
  ),
  checkout_time: Yup.string().required(
    "Please choose when you plan to check out"
  ),
  terms_accepted: Yup.boolean().oneOf(
    [true],
    "Please tick this box to confirm that you agree with the house rules"
  ),
  marketing_opt_in: Yup.boolean(),
  guestCanMakeExtraBedRequest: Yup.boolean(),
  extra_beds_required: Yup.mixed()
    .nullable()
    .when("guestCanMakeExtraBedRequest", {
      is: true,
      then: () =>
        Yup.mixed().oneOf(
          ["true", "false"],
          "Please let us know if you require extra beds"
        )
    }),
  travel_reason: Yup.string().required(
    "Please choose travel reason."
  )
});
