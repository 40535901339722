import "components/StayDetails/PropertyInfo.scss";
import "components/DetailsHeader/DetailsHeader.scss";

import {
  GettingAround,
  WiFi,
  NotesFromHost,
  TransportAndNeighbourhood,
  Parking,
  Amenities
} from "components/StayDetails/Sections";

import { InfoContainer } from "components/InfoContainer";
import {
  AppContextProvider,
  useAppContext
} from "../AppContext";
import { PropertyAccess } from "../components/StayDetails";

const InstructionsContainer = () => {
  const {
    booking: { property }
  } = useAppContext();

  if (property) {
    const { city, address } = property;
    return (
      <>
        <section className={`DetailsHeader City ${city}`}>
          <h1 className="main-header">
            Home info preview for {address}
          </h1>
        </section>

        <InfoContainer type="error">
          This preview is only for internal use. DO NOT send
          this page url to guests.
        </InfoContainer>

        <PropertyAccess />

        <section className="PropertyInfo">
          <GettingAround property={property} />
          <Parking property={property} />
          <TransportAndNeighbourhood property={property} />
          <Amenities property={property} />
          <WiFi property={property} />
          <NotesFromHost property={property} />
        </section>
      </>
    );
  }

  return null;
};

export const HomeInfoPreview = () => (
  <AppContextProvider mode="preview">
    <InstructionsContainer />
  </AppContextProvider>
);
