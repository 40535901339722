import {
  GoogleMap,
  Marker,
  useJsApiLoader
} from "@react-google-maps/api";
import config from "../config";

const mapContainerStyle = {
  width: "100%",
  height: 250
};

export const Map = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GOOGLE_API_KEY
  });

  const center = {
    lat,
    lng
  };

  if (lat && lng && isLoaded) {
    return (
      <div className="PropertyAccess__map">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={16}
          mapContainerClassName="PropertyAccess__map"
        >
          <Marker position={center} />
        </GoogleMap>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
          target="_blank"
          rel="noreferrer"
          className="directions-link"
        >
          Show directions
        </a>
      </div>
    );
  }

  return null;
};
