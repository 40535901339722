import * as Sentry from "@sentry/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useReviewContext } from "data/ReviewContext";
import { useAppContext } from "../../AppContext";

export const useStripePayments = () => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] =
    useState(null);

  const [walletsUnavailable, setWalletsUnavailable] =
    useState(false);

  const [paymentVendor, setPaymentVendor] = useState(null);

  const { booking } = useAppContext();

  const {
    handleSendTipAmount,
    stripeSecret,
    setStripeSecret,
    tipAmount,
    setTipCompleted,
    setTipError
  } = useReviewContext();

  const handlePaymentSuccess = amount => {
    Sentry.captureMessage("Tips: user paid tip");
    handleSendTipAmount(amount);
    setTipCompleted(true);
  };

  useEffect(() => {
    if (
      !booking?.property?.locality?.currency ||
      !booking?.property?.locality?.country_code
    ) {
      return;
    }
    // Prepares payment request with tip amount
    // paymentRequest object will be used to submit payment when user click on "Pay" button
    if (stripe) {
      setPaymentRequest(null);
      setStripeSecret(null);

      const pr = stripe.paymentRequest({
        country: booking?.property?.locality?.country_code,
        currency:
          booking.property.locality?.currency.toLowerCase(),
        total: {
          label: "Total",
          amount: tipAmount * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
      });

      pr.canMakePayment().then(result => {
        if (result) {
          // result is null if none methods are available
          // so it's either apple or google here
          setPaymentVendor(
            result.applePay ? "apple-pay" : "gpay"
          );
          setPaymentRequest(pr);
        } else {
          setPaymentRequest(null);
          setWalletsUnavailable(true);
        }
      });
    } else {
      setPaymentRequest(null);
    }
  }, [
    booking?.property?.locality?.currency,
    stripe,
    tipAmount
  ]);

  useEffect(() => {
    // Only bind once all is ready
    if (!paymentRequest || !stripeSecret) {
      return;
    }

    // This gets called once user fills or selects CC details
    paymentRequest.on("paymentmethod", async ev => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          stripeSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
        setTipError(true);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } =
            await stripe.confirmCardPayment(stripeSecret);

          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            Sentry.captureMessage("Tips: payment error");
            Sentry.captureException(error);
            setTipError(true);
          } else {
            handlePaymentSuccess(tipAmount);
          }
        } else {
          // The payment has succeeded.
          handlePaymentSuccess(tipAmount);
        }
      }
    });
  }, [stripeSecret, paymentRequest]);

  return {
    paymentVendor,
    paymentRequest,
    walletsUnavailable
  };
};
