import {
  ErrorMessage,
  Field,
  useFormikContext
} from "formik";
import { useReviewContext } from "data/ReviewContext";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";

export const PositiveSuggestions = () => {
  const { handleSetValues } = useReviewContext();
  const { handleBlur } = useFormikContext();
  const FIELD_NAME = "positiveSuggestions";

  const onBlur = event => {
    handleBlur(event);
    handleSetValues(event.target.value, FIELD_NAME);
  };

  return (
    <div className="ReviewForm__section">
      <p className="ReviewForm__text">
        We&#39;re glad the property was great! Please let us
        know if we may have done anything else to improve
        your stay:
      </p>

      <Field
        className="ReviewForm__textarea"
        as="textarea"
        name={FIELD_NAME}
        rows="5"
        onBlur={onBlur}
      />

      <ErrorMessage
        name={FIELD_NAME}
        component={ErrorMessageContainer}
      />
    </div>
  );
};
