import "./Checkbox.scss";
import classNames from "classnames";
import { ErrorMessage, Field } from "formik";
import { ErrorMessageContainer } from "components/ErrorMessageContainer";

export const Checkbox = ({
  name,
  id,
  label,
  invalid,
  value
}) => (
  <>
    <label htmlFor={name} className="Checkbox__label">
      <Field
        name={name}
        id={id || name}
        type="checkbox"
        value={value}
        className={classNames("Checkbox", {
          "Checkbox--invalid": invalid
        })}
      />
      <span>{label}</span>
    </label>

    <ErrorMessage
      name={name}
      component={ErrorMessageContainer}
    />
  </>
);
