import "./Option.scss";
import { Field } from "formik";

export const Option = ({ id, name, onChange, text }) => {
  return (
    <div key={id} className="Option">
      <Field
        id={id}
        className="Option__input"
        name={name}
        onChange={onChange}
        type="checkbox"
        value={id}
        data-testid={`input-${name}-${id}`}
      />
      <label
        htmlFor={id}
        className="Option__label"
        data-testid={`label-${name}-${id}`}
      >
        {text}
      </label>
    </div>
  );
};
