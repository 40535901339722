import { Map } from "../../../Map";
import { InfoContainer } from "../../../InfoContainer";
import React, { useContext } from "react";
import { AppContext } from "../../../../AppContext";

const AccessInstructionsUnavailable = () => {
  const {
    booking: { property }
  } = useContext(AppContext);
  return (
    <section className="PropertyAccess">
      <Map
        lat={property?.home_info?.location?.property_lat}
        lng={property?.home_info?.location?.property_lng}
      />
      <InfoContainer>
        <p>
          Information on how to enter the property will be
          displayed here 48 hours before check-in date.
        </p>
      </InfoContainer>
    </section>
  );
};

export default AccessInstructionsUnavailable;
