import "./ReviewPage.scss";
import {
  ReviewContextProvider,
  useReviewContext
} from "data/ReviewContext";
import { AppContextProvider } from "AppContext";
import loader from "assets/loader.webp";
import { ReviewDone, ReviewForm } from "components/Review";
import { ReviewTip } from "./ReviewTip";

const ReviewPage = () => {
  const {
    cleanerStripeAccount,
    tipsEnabled,
    isLoading,
    tipCompleted,
    isCompleted
  } = useReviewContext();

  if (isLoading) {
    return (
      <div className="loader-wrapper">
        <img src={loader} alt="Loader" className="loader" />
      </div>
    );
  }

  if (
    isCompleted &&
    cleanerStripeAccount &&
    tipsEnabled &&
    !tipCompleted
  ) {
    return <ReviewTip />;
  }

  if (isCompleted) {
    return <ReviewDone />;
  }

  return <ReviewForm />;
};

export const Review = () => (
  <AppContextProvider>
    <ReviewContextProvider>
      <ReviewPage />
    </ReviewContextProvider>
  </AppContextProvider>
);
