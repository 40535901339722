import "./InfoContainer.scss";
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

export const InfoContainer = ({
  children,
  testId,
  type = null
}) => {
  return (
    <div
      className={classNames("InfoContainer", {
        [`${type}`]: type
      })}
      data-testid={testId}
    >
      <InfoIcon />
      <div>{children}</div>
    </div>
  );
};
