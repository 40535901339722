import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import api from "services/api";
import { Input } from "components/Input/Input";
import { Button } from "components/Button";
import { useAppContext } from "AppContext";

const PHONE_REGEXP = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter your email address."),
  telephone: Yup.string()
    .matches(
      PHONE_REGEXP,
      "Number should start with a country code (e.g. +44) and be at least 6 digits."
    )
    .required("Please enter your phone number."),
  reason: Yup.string()
    .min(
      30,
      "Please enter more details (at least 30 characters)."
    )
    .required("Please tell us the reason for your stay.")
});

export const GuestDetailsForm = () => {
  const { booking, getBooking } = useAppContext();
  const {
    guest,
    reason_for_stay: reasonForStay,
    guest_email: guestEmail,
    guest_phone: guestPhone
  } = booking;
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    return {
      email: guest?.email || guestEmail || "",
      telephone: guest?.telephone || guestPhone || "",
      reason: reasonForStay || ""
    };
  }, [booking]);

  const handleSubmit = values => {
    api
      .put("/v1/guests/guest", null, values)
      .then(() => {
        getBooking();
      })
      .catch(err => {
        navigate("/error", {
          state: { errorMessage: err.message }
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="StayForm">
        <Input
          name="email"
          component="input"
          type="email"
          label="E-mail"
          placeholder="john@gmail.com"
        />

        <Input
          name="telephone"
          component="input"
          type="text"
          label="Telephone number"
          placeholder="+447912345678"
        />

        <Input
          name="reason"
          component="textarea"
          rows={4}
          label="Reason for stay"
          placeholder="I'm here on business..."
        />

        <Button type="submit"> Submit</Button>
      </Form>
    </Formik>
  );
};
